import api from '../utils/api';
import {
    GET_USER_SUCCESS,
    UPDATE_USER_SUCCESS,
    GET_USER_FAILURE,
    UPDATE_USER_FAILURE,
    GET_LESSONS_FAILURE,
    GET_LESSONS_SUCCESS,
    GET_LESSON_REQUEST,
    IMPORT_LESSONS_REQUEST,
    IMPORT_LESSONS_SUCCESS,
    IMPORT_LESSONS_FAILURE,
    GET_LESSONS_COUNT_SUCCESS,
    GET_LESSON_COUNT_FAILURE,
    GET_ACHIEVEMENT_COUNT_SUCCESS,
    GET_ACHIEVEMENT_COUNT_FAILURE,
    SET_FILTER,
    SET_PAGE,
} from './types';

export const getSlp = (userId) => {
    return (dispatch) => {
        api.get(`/slps/slps/${userId}`)
        .then(res => {
          dispatch({ type: GET_USER_SUCCESS, payload: res.data });
        })
        .catch(err => {
          dispatch({ type: GET_USER_FAILURE, payload: err.message });
        });
    };
  };
  
  export const updateSlp = (userId, userData) => {
    return (dispatch) => {
        api.put(`/slps/slps/${userId}`, userData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => {
          dispatch({ type: UPDATE_USER_SUCCESS, payload: res.data });
        })
        .catch(err => {
          dispatch({ type: UPDATE_USER_FAILURE, payload: err.message });
        });
    };
  };

  export const submitLesson = async (formData) => {console.log(formData);
    try {
      const response = await api.post('/slps/addlesson', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      throw new Error('Error submitting form data');
    }
  };

  export const getLessons = (filter, page) => async (dispatch) => {
    try {
      dispatch({ type: GET_LESSON_REQUEST });
      const url = `/slps/lessons?filter=${filter}&page=${page}`;
      
      const res = await api.get(url);

      const { lessons, totalPages } = res.data;

      if (totalPages) {
        dispatch({ type: GET_LESSONS_SUCCESS, payload: { lessons, totalPages } });
      } else {
       
        dispatch({ type: GET_LESSONS_SUCCESS, payload: { lessons } });
      }
    } catch (error) {
      dispatch({ type: GET_LESSONS_FAILURE, payload: error.message });
    }
  };

  export const importLessons = (file) => {
    return async (dispatch) => {
      dispatch({ type: IMPORT_LESSONS_REQUEST });
  
      try {
        const formData = new FormData();
formData.append('file', file);
console.log([...formData.entries()]);
        const response = await api.post('/slps/importdata', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
  
        dispatch({ type: IMPORT_LESSONS_SUCCESS, payload: response.data });
      } catch (error) {
        dispatch({ type: IMPORT_LESSONS_FAILURE, payload: error.message });
      }
    };
  };


  export const getLessonCount = () => {
    return async (dispatch) => {
      try {
        const response = await api.get('/slps/count');
        dispatch({ type: GET_LESSONS_COUNT_SUCCESS, payload: response.data.count });
      } catch (error) {
        console.error('Error fetching student count:', error.message);
        dispatch({ type: GET_LESSONS_COUNT_FAILURE });
      }
    };
  };

  export const getAchievementCount = (slpid) => {
    return async (dispatch) => {
      try {
        const response = await api.get(`/slps/studentsandreports/${slpid}`);
        dispatch({ type: GET_ACHIEVEMENT_COUNT_SUCCESS, payload: response.data });
      } catch (error) {
        console.error('Error fetching student count:', error.message);
        dispatch({ type: GET_ACHIEVEMENT_COUNT_FAILURE });
      }
    };
  };

  export const setFilter = (filter) => ({
    type: SET_FILTER,
    payload: filter,
  });
  
  export const setPage = (page) => ({
    type: SET_PAGE,
    payload: page,
  });