import React, { useEffect } from 'react';
import { Link,Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect,useDispatch,useSelector } from 'react-redux';
import DashboardActions from './DashboardActions';
import Experience from './Experience';
import Education from './Education';
import { getCurrentProfile, deleteAccount } from '../../actions/profile';
import {getSlpCount,getStuCount,getActSlp,getActStuCount} from '../../actions/admin';
import LeftNav from '../../components/layout/LeftNav';
import Navbar from '../../components/layout/Navbar';
import Chart from '../dashboard/VerticalBarChart';
import TeacherData from '../admin/TeachersGrid';
import StudentData from '../admin/StudentsGrid';
import utime from '../../images/uptime.png';
import totalslps from '../../images/tslps.png';
import totalstu from '../../images/tstu.png';
import aslps from '../../images/aslps.png';
import astudent from '../../images/astu.png';
import dalert from '../../images/dalert.png';

const Dashboard = ({
  getCurrentProfile,
  isOpen,
  deleteAccount,
  auth: { user },
  profile: { profile }
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    getCurrentProfile();
    dispatch(getSlpCount());
    dispatch(getStuCount());
    dispatch(getActSlp());
    dispatch(getActStuCount());
  }, [getCurrentProfile,getSlpCount]);

  
  const { slpCount,stuCount,actSlpCount,actstucount} = useSelector((state) => state.admin);
  if (user && user.user && user.user.usertype == 'slpuser') {
    
    return <Navigate to="/slp/dashboard" />;
} else if(user && user.user && user.user.usertype == 'parent'){
  return <Navigate to="/parent/dashboard" />;
}
  

  return (
    <section>
      <div className="App">
      <div className="container-fluid">
        <div className="row">
          
          {/* Left Navigation Sidebar */}
          <div className="col-md-3">
            <LeftNav/>
          </div>

          {/* Main Content */}
          <div className={`col-md-12 ${isOpen ? 'content' : 'togcontent'}`}>
          <Navbar/>
            <div className={`p-20 ${isOpen ? 'innercontent' : 'toginnercontent'}`}>
           
              <div><h4>Dashboard</h4></div>
                <div className="row">
                    <div className='col-lg-5'>
                            <div className="row dashboard">
                                <div className="col-lg-6">
                                    <div className="card card-fill text-white" style={{backgroundColor:'#5058F0'}}>
                                        
                                        <div className="card-body dashcard">
                                        <div className='dcardvalue'>{slpCount}</div>
                                            <div className="mb-0 float-left">Total SLPs</div>
                                            <div><img src={totalslps} /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card card-fill text-white" style={{backgroundColor:'#FF8423'}}>
                                      
                                        <div className="card-body dashcard">
                                        <div className='dcardvalue'>{stuCount}</div>
                                            <div className="mb-0 float-left">Total Students</div>
                                            <div><img src={totalstu} /></div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className="row dashboard">
                                    <div className="col-lg-6">
                                        <div className="card card-fill text-white" style={{backgroundColor:'#00AFF0'}}>
                                            
                                            <div className="card-body dashcard">
                                            <div className='dcardvalue'>{actSlpCount}</div>
                                                <div className="mb-0 float-left">Active SLPs</div>
                                                <div><img src={aslps} /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="card card-fill text-white" style={{backgroundColor:'#FFC107'}}>
                                            
                                            <div className="card-body dashcard">
                                            <div className='dcardvalue'>{actstucount}</div>
                                                <div className="mb-0 float-left">Active Students</div>
                                                <div><img src={astudent} /></div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div className="row dashboard">
                                    <div className="col-lg-6">
                                        <div className="card card-fill text-white" style={{backgroundColor:'#FF5274'}}>
                                            
                                            <div className="card-body dashcard">
                                                <div className='dcardvalue'>0</div>
                                                <div className="mb-0 float-left">Alerts<image src={dalert} /></div>
                                                <div><img src={dalert} /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="card card-fill text-white" style={{backgroundColor:'#660090'}}>
                                            
                                            <div className="card-body dashcard">
                                            <div className='dcardvalue'>3</div>
                                                <div className="mb-0 float-left">System Uptime</div>
                                                <div><img src={utime} /></div>
                                            </div>
                                        </div>
                                    </div>
                            </div>

                    </div>

                    <div className='col-lg-7'>
                      <div className="card  text-white">
                        <div className="card-body">
                          
                            <Chart/>
                        </div>
                    </div>
                  </div>  

              

              </div>
              <div className='row'>
              <div className='col-lg-12'>
                <div className='row'>
                  <div className='col-lg-11'><h4><strong>Teachers</strong></h4></div>
                  <div className='col'><a href='/admin/teachers' style={{color:'#008000',textDecoration:'none',fontWeight:'bold'}}>See all</a></div>
                </div>
                
                      <div className="card  text-white">
                        <div className="card-body">
                        <TeacherData/>
                        </div>
                    </div>
                  </div>  
              </div>


              <div className='row'>
              <div className='col-lg-12'>
              <div className='row'>
                  <div className='col-lg-11'><h4><strong>Students</strong></h4></div>
                  <div className='col'><a href='/admin/students' style={{color:'#008000',textDecoration:'none',fontWeight:'bold'}}>See all</a></div>
                </div>
                      <div className="card  text-white">
                        <div className="card-body">
                        <StudentData/>
                        </div>
                    </div>
                  </div>  
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
  );
};

Dashboard.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  isOpen: state.auth.isOpen !== undefined ? state.auth.isOpen : true, 
});

export default connect(mapStateToProps, { getCurrentProfile, deleteAccount })(
  Dashboard
);
