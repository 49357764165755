import React, { useEffect,useState  } from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { connect,useDispatch, useSelector } from 'react-redux';
import LeftNav from '../layout/LeftNav';
import Navbar from '../layout/Navbar';
import { getCurrentProfile, deleteAccount } from '../../actions/profile';
import slpal from '../../images/slpal.png';
import 'react-datepicker/dist/react-datepicker.css';
import rarrow from '../../images/rawwow.png';
import {addSlp} from '../../actions/admin';
const AddTeacher = ({
  getCurrentProfile,
  isOpen,
  auth: { user },
  profile: { profile }
}) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    title: '',
    dob: null,
    avatar: null // Add profileImage field to form data
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, dob: date });
  };

  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, avatar: file });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Create a FormData object
    const formDataToSend = new FormData();
    // Append other form data
    formDataToSend.append('firstname', formData.firstname);
    formDataToSend.append('lastname', formData.lastname);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('phone', formData.phone);
    formDataToSend.append('title', formData.title);
    formDataToSend.append('dob', formData.dob);
    // Append profile image
    formDataToSend.append('avatar', formData.avatar);
    try{
      await dispatch(addSlp(formDataToSend));
      
  }catch(error){

  }
  };

  return (
    <section>
      <div className="App">
      <div className="container-fluid">
        <div className="row">
          {/* Left Navigation Sidebar */}
          <div className="col-md-3">
            <LeftNav/>
          </div>

          {/* Main Content */}
          <div className={`col-md-12 ${isOpen ? 'content' : 'togcontent'}`}>
            <Navbar/>
            <div className={`p-20 ${isOpen ? 'innercontent' : 'toginnercontent'}`} style={{height:'100vh'}}>
                <div className='row'>
                    <div className='col'><h4>Create SLP Account</h4></div>
                </div>
              <div className='card' style={{height:'80vh'}}>
                <div className='row card-body' style={{padding:'0px'}}>
                    <div className='col-lg-2' style={{background:'#41A66C'}}><img style={{width:'80px',position: 'relative',top: '50%',transform: 'translateY(-50%)',margin:'0 auto', display:'block'}} src={slpal}/></div>
                    <div className='col-lg-10'>
                        
                        <div>
                        

                        <div className="container p-20">
      
      <form enctype="multipart/form-data" onSubmit={handleSubmit}>
      
      <div className='row'>
      <div className="mb-3 col">
      <label  className="form-label" style={{fontStyle:'italic',fontWeight:'bold'}}>First Name:</label>
          <input type="text" className="form-control" name='firstname' value={formData.firstname} onChange={handleChange} />
        </div>
        <div className="mb-3 col">
        <label  className="form-label" style={{fontStyle:'italic',fontWeight:'bold'}}>Last Name:</label>
          <input type="text" className="form-control" name='lastname' value={formData.lastname} onChange={handleChange} />
        </div>
        </div>
        <div className="mb-3">
        <label className="form-label" style={{fontStyle:'italic',fontWeight:'bold'}}>Phone:</label>
          <input type="text" className="form-control" name='phone' value={formData.phone} onChange={handleChange} />
        </div>
        <div className="mb-3">
        <label  className="form-label" style={{fontStyle:'italic',fontWeight:'bold'}}>Title:</label>
          <input type="text" className="form-control" name='title' value={formData.title} onChange={handleChange} />
        </div>
        
        <div className="mb-3">
        <label htmlFor="field1" className="form-label" style={{fontStyle:'italic',fontWeight:'bold'}}>Email:</label>
          <input type="email" className="form-control" name='email' value={formData.email} onChange={handleChange} />
        </div>
        <div className="mb-3">
        <label htmlFor="field1" className="form-label" style={{fontStyle:'italic',fontWeight:'bold'}}>Date of Birth:</label>
         
          <DatePicker
        selected={formData.dob}
        onChange={handleDateChange}
        dateFormat="yyyy-MM-dd"
        placeholderText="Select date of birth"
        dateFormat="yyyy-MM-dd" showMonthDropdown yearDropdownItemNumber={150} showYearDropdown  scrollableYearDropdown
      />
        </div>
        <div className="mb-3">
        <label htmlFor="field1" className="form-label" style={{fontStyle:'italic',fontWeight:'bold'}}>Avatar:</label>
          <input type="file" className="form-control" accept="image/*" onChange={handleProfileImageChange} />
        </div>
        
        <div style={{float:'right'}}><button type="submit" className='btn' style={{background:'#41A66C',color:'white',width:'120px'}}>Save <img style={{width:'8px',float:'right',paddingTop:'6px'}} src={rarrow}/></button></div>
      </form>
    </div>



                        </div>
                    </div>
                </div>
              </div>




             
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
  );
};

AddTeacher.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  isOpen: state.auth.isOpen !== undefined ? state.auth.isOpen : true,
});

export default connect(mapStateToProps, { getCurrentProfile, deleteAccount })(
    AddTeacher
);
