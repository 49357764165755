
import React, { useEffect,useState } from 'react';
import { useParams } from 'react-router-dom';
import { logout,setIsOpen } from '../../actions/auth';
import logo from '../../images/tempo-talk-logo.svg';
import dashicon from '../../images/Icon-Dashboard.png';
import studenticon from '../../images/Icon-Students.png';
import teachericon from '../../images/Icon-Lessons.png';
import analytics from '../../images/Icon-Analytics.png';
import setting from '../../images/Icon-Setting.png';
import lesson from '../../images/Icon-Lessons.png';
import signout from '../../images/sign-out.png';
import openclose from '../../images/oc.png';
import { getParent} from '../../actions/parent';
import { connect,useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
const ParentLeftNav = ({isOpen, setIsOpen,logout,auth: { user } }) =>{
  
  const dispatch = useDispatch();
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsOpen(false);
    } 
  };

  const [showComponent, setShowComponent] = useState(false);
  const { id } = useParams();
  

  
  
  const {estudent, student, loading, error, filter, page,parent } = useSelector((state) => state.parent);

    useEffect(() => {
      if (id) {
        setShowComponent(true);
      } else {
        setShowComponent(false);
      }
      dispatch(getParent(user.user.id));
      handleResize();
    }, [dispatch]); 

  const currentPath = window.location.pathname;
  const stuid = (student[0])?student[0]._id:'';
  return (
    <div className={`left-nav ${isOpen ? 'open' : 'closed'}`}>
           
    <div className="sidebar-header">
    <div><img  src={logo} alt="logo" className='tempologo'/></div>
      {/* Toggle button */}
      <img
          src={openclose} // Use the imported openclose image
          alt="Toggle Menu"
          className={`${isOpen ? 'toggle-img' : 'togtoggle-img'}`}
          onClick={toggleMenu}
        />
    </div>
    <ul className={`sidebar-menu accordion-menu ${isOpen ? ' p-t-100' : ' '}`}>
      
    <li><a href='/parent/dashboard'><img className='menuicon' src={dashicon} alt="dashicon" /><span className="hide-sm">Dashboard</span></a></li>
      <li><a href={`/parent/editprofile/${stuid}`}><img className='menuicon' src={teachericon} alt="teachericon" /><span className="hide-sm">Profile</span></a>
</li>
<li className='btop'>
          <a onClick={logout} href="#!">
            <img src={signout} className='menuicon' style={{marginRight:'20px'}}/>{' '}
            <span className="hide-sm">Logout</span>
          </a>
        </li>
      {/* You can add more items here */}
      

   



    </ul>
  </div>
  );
}


ParentLeftNav.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  setIsOpen: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  isOpen: state.auth.isOpen !== undefined ? state.auth.isOpen : true,
});

export default connect(mapStateToProps, { logout,setIsOpen })(ParentLeftNav);


