import React, { useState,useEffect  } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect,useDispatch,useSelector   } from 'react-redux';
import PropTypes from 'prop-types';
import { login,loadUser } from '../../actions/auth';
import logo from '../../images/tempo-talk-logo.svg';
import Illustration from '../../images/fgotpass.svg';
import sdtrw from '../../images/sdtrw.svg';

const ForgotPassword = ({ login, isAuthenticated,loadUser }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });


  const { email, password } = formData;
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    try {
      const response = await login(email, password);
      setLoading(false);
      if (response.success) {
        setMessage('Login successful. Redirecting...');
       // history.push('/dashboard');
      } else {
        setMessage(response.error || 'Login failed. Please try again.');
      }
    } catch (error) {
      setLoading(false);
      setMessage('An error occurred. Please try again later.');
      console.error('Error signing in:', error);
    }
  };



  
  if (isAuthenticated) {
    
    return <Navigate to="/dashboard" />;
  }

  return (
    <section>
     
     {loading && (
              <div className="overlay d-flex justify-content-center align-items-center">
              <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
              </div>
          </div>
            )}
     <div className="container-fluid">
    <div className="row">
     
      <div className="col-md-6" style={{background:'#F7F7FC'}}>
      <div className='m-l-140 m-t-10'>
        <div><img  src={logo} alt="logo" /></div>
        <div><img src={sdtrw} alt="sdtrw" /></div>
        </div>
        <div className="custom-contentRight" style={{width:'70%',paddingTop:'100px'}}>
        {message && <div className={`alert ${message.startsWith('Login successful') ? 'alert-success' : 'alert-danger'}`}>{message}</div>}
        <h3 className="m-l-10"><b>Forgot Password</b></h3>
      <p style={{paddingBottom:'50px'}}>
      Enter the email address you used when you registered and we’ll send you instructions to reset your password.

For security reasons, we do NOT store your password. So rest assured that we will never send your password via email.
      </p>
      <form className="form-horizontal" onSubmit={onSubmit}>
      
        <div className="form-group mb-3">
        <div className="col-12">
        <label className="form-label">Email</label>
          <input
          className='form-control'
            type="email"
            placeholder="input your email in here"
            name="email"
            value={email}
            onChange={onChange}
          />
          </div>
          <div className='forpass'><Link to="/">Login</Link> </div>
        </div>
        
        <div className="form-group text-center m-t-40">
        <div className="col-12">
        <input type="submit" className="btn btn-primary w-100 waves-effect waves-light" value="Reset Password" />
        <div className='nhelp m-t-10'>Need help? Contact Us! <Link to="/">Get Help</Link> </div>
        </div>
        </div>
        
      </form>
      
        </div>
      </div>
      <div className="col-md-6 loginleft" style={{background:'#E2E2EA'}}>
        
        <div className="custom-contentLeft">
        
        <img  src={Illustration} alt="Illustration" />
        </div>
      </div>
    </div>
  </div>


      
    </section>
    
  );
};

ForgotPassword.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { ForgotPassword })(ForgotPassword);
