import React, { useState,useEffect  } from 'react';
import { DataGrid,GridToolbar  } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import right from '../../images/Right.png';
import dact from '../../images/Deactive.png';
import { getGridTeachers,setTracherStatus,deleteTeacherById  } from '../../actions/admin';


const TeachersDataGrid = () => {
  

  const dispatch = useDispatch();
  const { slps, loading, error, filter } = useSelector(state => state.admin);  

  useEffect(() => {
    dispatch(getGridTeachers());
  }, [dispatch]); 
  const studentsWithSerial = slps.map((slp, index) => ({
    ...slp,
    serial: index + 1 // Index starts from 0, so add 1 to start from 1
  }));

  const handleSetStatus = (id, status) => {
    dispatch(setTracherStatus(id, status));
   // onStudentAdded();
  };
  const handleDelete = (id) => {
    if (window.confirm('Are you sure you want to delete this student?')) {
      dispatch(deleteTeacherById(id));
      location.reload(); 
    }
  };
  const columns = [
    {field: 'serial', headerName: 'No',width: 150},
    { 
        field: 'fullname', 
        headerName: 'Name', 
        width: 150, 
        valueGetter: (params) => `${params.firstname} ${params.lastname}`
      },
    { field: 'students', headerName: 'Students',width: 250},
    { field: 'status', headerName: 'Status', width: 200},
    { field: 'date', headerName: 'Registered', width: 200},
    { field: 'ratting', headerName: 'Rating', width: 150 },
  { field: 'actions', headerName: 'Actions'},
  ];
  
  const handleYesClick = () => {
    // Handle 'Yes' action here
    console.log('Yes clicked');
    closeModal();
  };

  const handleNoClick= () => {
    // Handle 'Yes' action here
    console.log('Yes clicked');
    closeModal();
  };
  const closeModal = () => {
    const modal = document.getElementById('myModal');
    modal.style.display = 'none';
    document.body.classList.remove('modal-open');
  };
  const [searchText, setSearchText] = useState('');

  // Function to handle search text change
  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  // Filter rows based on search text
  const filteredRows = studentsWithSerial.filter(studentsWithSerial =>
    Object.values(studentsWithSerial).some(value =>
      value.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

 
  const RowWithTogglableDiv = ({ row }) => {
    const [isExpanded, setIsExpanded] = React.useState(false);
  
    const toggleDiv = () => {
      setIsExpanded(!isExpanded);
    };
  
    return (
      <div className='MuiDataGrid-virtualScroller'>
        <div className='MuiDataGrid-row' style={{width:'1190px',background:'white'}}>
    {columns.map((column,index) => {
      // Check if the current column is for the 'fullname' field
      if (column.field === 'fullname') {
        // Concatenate firstname and lastname
        const fullName = `${row.firstname} ${row.lastname}`;
        // Render the concatenated full name
        return (
          <div key={column.field} className="MuiDataGrid-cell MuiDataGrid-cell--textLeft MuiDataGrid-withBorderColor" style={{ flex: `0 0 ${column.width}px`, minHeight:'55px',maxHeight:'55px' }}>
            <span>{fullName}</span>
          </div>
        );
      }else if(column.field === 'students') {
        
        return (
          <div key={column.field} className="MuiDataGrid-cell MuiDataGrid-cell--textLeft MuiDataGrid-withBorderColor" style={{ flex: `0 0 ${column.width}px`, minHeight:'55px',maxHeight:'55px' }}>
            <span>{index+2}</span>
          </div>
        );
      }else if(column.field === 'ratting') {
        
        return (
          <div key={column.field} className="MuiDataGrid-cell MuiDataGrid-cell--textLeft MuiDataGrid-withBorderColor" style={{ flex: `0 0 ${column.width}px`, minHeight:'55px',maxHeight:'55px' }}>
            <span>{index-3}</span>
          </div>
        );
      }else{
        // Render other columns normally
        return (
          <div key={column.field} className="MuiDataGrid-cell MuiDataGrid-cell--textLeft MuiDataGrid-withBorderColor" style={{ flex: `0 0 ${column.width}px`, minHeight:'55px',maxHeight:'55px' }}>
            <span style={{color: row[column.field] === 'Active' ? 'green' : row[column.field] === 'Inactive' ? 'red' : ''}}>{row[column.field]}</span>
          </div>
        );
      }
    })}
    
    <div className='MuiDataGrid-cell MuiDataGrid-cell--textLeft MuiDataGrid-withBorderColor'>
      <a href={`/slp/studentprofile/${row._id}`}><img src={right} alt="Edit" style={{width:'25px',cursor:'pointer',marginRight:'5px'}}/></a>
      <img src={dact} alt="Delete" onClick={toggleDiv} style={{width:'25px',cursor:'pointer'}}/>
    </div>
  </div>
        <div
          style={{
            height: isExpanded ? '30px' : '0',
            overflow: 'hidden',
            transition: 'height 0.3s ease',
            background: '#fff',
            textAlign:'right',
            marginTop:'-5px',
            marginBottom:'10px',
            boxShadow:'0 0 6px 3px rgba(0, 0, 0, 0.3)',
          paddingRight:'10px'
          }}
        >
          {/* Contents of the togglable div */}
          <a style={{color:'#424242',textDecoration:'none'}} href="#" onClick={() => handleSetStatus(row._id, false)}>Deactivate</a> | <a style={{color:'#424242',textDecoration:'none'}} href="#" onClick={() => handleDelete(row._id)}>Suspend</a>
        </div>
      </div>
    );
  };
  return (
    
    <div>
      
     <div style={{float:'right'}}><input
        type="text"
        value={searchText}
        onChange={handleSearchTextChange}
        placeholder="Search..."
      /></div>
      <div style={{clear:'both'}}></div>
    <DataGrid
    
        rows={filteredRows}
        columns={columns}
        pageSize={5}
        getRowId={(row) => row._id}
        components={{
          Row: RowWithTogglableDiv,
        }}
        
      />
    
    <div id="myModal" className="modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Confirmation</h5>
              
            </div>
            <div className="modal-body">
              Are you sure you want to proceed?
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={handleNoClick}>No</button>
              <button type="button" className="btn btn-primary" onClick={handleYesClick}>Yes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  
  );
};

export default TeachersDataGrid;
