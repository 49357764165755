import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDashStudents } from '../../actions/students';
import cardback from '../../images/cardback.png';
import man1 from '../../images/man1.png';
import man2 from '../../images/man2.png';
import man3 from '../../images/fem3.png';
import rvl from '../../images/glesson.png';
import imgfilter from '../../images/Filter.png';
import stickers from '../../images/ws.png';
import sticker01 from '../../images/sticker-01.png';

const DashSlpStu = () => {
  const dispatch = useDispatch();
  const [flippedLessons, setFlippedLessons] = useState([]);
  const [flippedStickers, setFlippedStickers] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [filterBy, setFilterBy] = useState('Active');
  const [currentPage, setCurrentPage] = useState(1);
  const [startLessonIndex, setStartLessonIndex] = useState(0);
  const numToShow = 6;

  const students = useSelector((state) => state.student.students);

  useEffect(() => {
    if (!filterBy) {
      setFilterBy('Active');
    }
  }, [filterBy]);

  useEffect(() => {
    dispatch(getDashStudents(filterBy));
  }, [dispatch, filterBy]);

  useEffect(() => {
    const filteredData = students.filter((student) => {
      if (filterBy === 'Active') return student.stuStatus === 'Active';
      if (filterBy === 'Inactive') return student.stuStatus === 'Inactive';
      return student.lessonMap && student.studentReports;
    });
    setFilteredStudents(filteredData);
  }, [students, filterBy]);

  const handleFilterChange = (event) => {
    setFilterBy(event.target.value);
    setCurrentPage(1);
  };

  const nextPage = (event) => {
    event.preventDefault();
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const prevPage = (event) => {
    event.preventDefault();
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const goToPage = (pageNumber, event) => {
    event.preventDefault();
    setCurrentPage(pageNumber);
  };

  const toggleShowMore = () => {
    setStartLessonIndex(startLessonIndex + numToShow);
  };

  const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(false);

    const updateMatches = () => {
      const mediaQuery = window.matchMedia(query);
      setMatches(mediaQuery.matches);
    };

    useEffect(() => {
      updateMatches();
      window.addEventListener('resize', updateMatches);

      return () => {
        window.removeEventListener('resize', updateMatches);
      };
    }, [query]);

    return matches;
  };

  const isMobile = useMediaQuery('(max-width: 768px)');
  const employeesPerPage = isMobile ? filteredStudents.length : 5;
  const totalPages = Math.ceil(filteredStudents.length / employeesPerPage);
  const indexOfLastDiv = currentPage * employeesPerPage;
  const indexOfFirstDiv = indexOfLastDiv - employeesPerPage;
  const currentEmployees = filteredStudents.slice(indexOfFirstDiv, indexOfLastDiv);

  return (
    <div className="row">
      <div className="row p-b-15">
        <div className='col-lg-10'><h4 style={{ fontWeight: 'bold', paddingLeft: '10px' }}>Students</h4></div>
        <div className='col'>
          <div className='row'>
            <div className='col-lg-2'><img style={{ width: '38px' }} src={imgfilter} alt="Filter" /></div>
            <div className='col'>
              <select
                style={{ float: 'right', width: '135px', height: '32px', borderRadius: '5px', border: 'none', background: 'white', fontSize: '15px' }}
                id="filter"
                value={filterBy}
                onChange={handleFilterChange}
              >
                <option value="all">All</option>
                <option value="Active">Sort by: Active</option>
                <option value="Inactive">Sort by: Inactive</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="empcontainer">
        <div className="employee-container">
          {currentEmployees.map((student, index) => (
            <div key={student._id} className={`col-lg-${isMobile ? '12' : '3'} col-md-6 col-12`} style={{ marginBottom: '20px', width: isMobile ? '100%' : '20%' }}>
              <div className="flip-card">
                <div className={`flip-card-inner ${(flippedLessons.includes(student._id) || flippedStickers.includes(student._id)) ? 'flipped' : ''}`}>
                  <div className={student.stuStatus === 'Inactive' ? 'flip-card-front bdrRed' : 'flip-card-front'} style={{ borderRadius: '10px' }}>
                    <div className="card-header" style={{ backgroundImage: `url(${cardback})`, height: '130px', width: '100%', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
                      <img src={[man1, man2, man3][index % 3]} alt={`Avatar ${index % 3 + 1}`} className="avatar" />
                    </div>
                    <div className="card-body" style={{ paddingTop: '80px', textAlign: 'center', margin: '0 auto' }}>
                      <h5 style={{ fontWeight: 'bold', fontSize: '16px' }}>Student ID: {student._id}</h5>
                      <div className='center'>
                        <div className='btnbdr float-left'>
                          <img src={rvl} alt="rvl" />
                          <div style={{ fontWeight: 'bold', fontSize: '16px' }}>Lessons</div>
                          <div
                            className={student.stuStatus === 'Inactive' ? 'courBdrred' : 'courBdrgreen'}
                            onClick={() => setFlippedLessons(flippedLessons.includes(student._id) ? flippedLessons.filter(cardId => cardId !== student._id) : [...flippedLessons, student._id])}
                          >
                            {student.stuLessonId.length}
                          </div>
                        </div>
                        <div className='btnbdr float-left'>
                          <img src={stickers} alt="stickers" />
                          <div style={{ fontWeight: 'bold', fontSize: '16px' }}>Stickers</div>
                          <div
                            className='courBdrgreen'
                            onClick={() => setFlippedStickers(flippedStickers.includes(student._id) ? flippedStickers.filter(cardId => cardId !== student._id) : [...flippedStickers, student._id])}
                          >
                            {student.studentReports ? student.studentReports.reduce((total, report) => total + (report.lachievement ? report.lachievement.length : 0), 0) : 0}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flip-card-back" style={{ display: (flippedLessons.includes(student._id) || flippedStickers.includes(student._id)) ? 'block' : 'none' }}>
                    <div className="card text-white" style={{ backgroundImage: `url(${cardback})`, height: '100%' }}>
                      <div className={index === 0 ? 'card-body bdrRed' : 'card-body'}>
                        <h6><span>Student Id: </span>{student._id}</h6>
                        <h6><span>Grade: </span>{student.stuGrade}</h6>
                        <h6><span>Registered: </span>{student.date}</h6>
                        <>
                          {flippedLessons.includes(student._id) && (
                            <>
                              {student.stuLessonId.slice(startLessonIndex, startLessonIndex + numToShow).map((lessonId, idx) => (
                                <div key={idx}>
                                  {student && student.lessonMap && student.lessonMap[lessonId] ? student.lessonMap[lessonId] : ''}
                                  <div className="progress">
                                    <div className="progress-sm progress-bar-success" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                                  </div>
                                </div>
                              ))}
                              <a href="#" className="btn btn-secondary" onClick={() => { setStartLessonIndex(0); setFlippedLessons(flippedLessons.filter(cardId => cardId !== student._id)); }}>Close</a>
                              {student.stuLessonId.length > startLessonIndex + numToShow && (
                                <a href="#" style={{ marginTop: '5px' }} className="btn btn-primary btn-sm" onClick={toggleShowMore}>Show more</a>
                              )}
                            </>
                          )}
                        </>
                        {flippedStickers.includes(student._id) && (
                          <div className='row'>
                            {student.studentReports ? (
                              student.studentReports.map((report, reportIdx) => (
                                report.lachievement.map((achievement, achievementIdx) => (
                                  <div key={achievementIdx} className='col'>
                                    <img style={{ width: '60px', padding: '7px' }} src={sticker01} alt={`sticker${achievementIdx}`} />
                                  </div>
                                ))
                              ))
                            ) : ('')}
                            <a href="#" className="btn btn-secondary" onClick={() => setFlippedStickers(flippedStickers.filter(cardId => cardId !== student._id))}>Close</a>
                            <a href={`/slp/studentprofile/${student._id}`} className="btn btn-secondary btn-sm" style={{ background: '#008000', padding: '5px 30px 5px 30px', marginTop: '20px', width: '190px' }} role="button" aria-pressed="true">View Profile</a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="pagination">
        <button onClick={prevPage}>Previous</button>
        {Array.from({ length: totalPages }, (_, i) => (
          <button key={i} className={currentPage === i + 1 ? 'active' : ''} onClick={(e) => goToPage(i + 1, e)}>
            {i < 9 ? `0${i + 1}` : i + 1}
          </button>
        ))}
        <button onClick={nextPage}>Next</button>
      </div>
    </div>
  );
};

export default DashSlpStu;
