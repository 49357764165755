import React, { useState, useRef,useEffect } from 'react';
import { useDispatch,connect,useSelector  } from 'react-redux';
import DatePicker from 'react-datepicker';
import { getCurrentProfile, deleteAccount } from '../../actions/profile';
import { getSlp, updateSlp } from '../../actions/slp';
import SlpNavbar from '../../components/layout/SlpNavbar';
import Navbar from '../../components/layout/Navbar';
import slpal from '../../images/slpal.png';
import 'react-datepicker/dist/react-datepicker.css';
import rarrow from '../../images/rawwow.png';
const Profile = ({
  getCurrentProfile,
  userId ,
  auth: { user },
  profile: { profile }
}) => {
  useEffect(() => {
    getCurrentProfile();
  }, [getCurrentProfile]);

  const dispatch = useDispatch();
  const Slp = useSelector(state => state.slp.slp);
  const error = useSelector(state => state.slp.error);
  
  
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [title, setTitle] = useState('');
  const [dob, setDob] = useState(null);
  const [avatar, setAvatar] = useState(null);


  useEffect(() => {
    dispatch(getSlp(user.user.id));
  }, [dispatch, user.user.id]);

  useEffect(() => {
    
    setEmail(Slp.email || '');
    setFirstName(Slp.firstname || '');
    setLastName(Slp.lastname || '');
    setPhone(Slp.phone || '');
    setTitle(Slp.title || '');
    setDob(Slp.dob || '');
  }, [Slp]);

  const handleAvatarChange = (e) => {
    setAvatar(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('id', user.user.id);
    formData.append('firstname', firstname);
    formData.append('lastname', lastname);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('title', title);
    
      formData.append('dob', dob);
   
    if (avatar) {
      formData.append('avatar', avatar);
    }
    try{
        await dispatch(updateSlp(user.user.id, formData));
        
    }catch(error){

    }
  };
  console.log('User:', user.user.id);
  return (
    <section>
      <div className="App">
      <div className="container-fluid">
        <div className="row">
          {/* Left Navigation Sidebar */}
          <div className="col-md-3">
            <SlpNavbar/>
          </div>

          {/* Main Content */}
          <div className="col-md-12 content">
            <Navbar/>
            <div className='innercontent p-20'>
                <div className='row'>
                    <div className='col'><h4>Profile</h4></div>
                </div>
              <div className='card'>
                <div className='row card-body' style={{padding:'0px'}}>
                    <div className='col-lg-2' style={{background:'#41A66C'}}><img style={{width:'80px',position: 'relative',top: '50%',transform: 'translateY(-50%)',margin:'0 auto', display:'block'}} src={slpal}/></div>
                    <div className='col-lg-10'>
                        
                        <div>
                        

                        <div className="container p-20">
      <h2>User Profile</h2>
      {error && <p>{error}</p>}
      <form enctype="multipart/form-data" onSubmit={handleSubmit}>
      <input type="hidden" name="id" value={user.user.id} />
      <div className='row'>
      <div className="mb-3 col">
      <label htmlFor="field1" className="form-label" style={{fontStyle:'italic',fontWeight:'bold'}}>First Name:</label>
          <input type="text" className="form-control" value={firstname} onChange={(e) => setFirstName(e.target.value)} />
        </div>
        <div className="mb-3 col">
        <label htmlFor="field1" className="form-label" style={{fontStyle:'italic',fontWeight:'bold'}}>Last Name:</label>
          <input type="text" className="form-control" value={lastname} onChange={(e) => setLastName(e.target.value)} />
        </div>
        </div>
        <div className="mb-3">
        <label htmlFor="field1" className="form-label" style={{fontStyle:'italic',fontWeight:'bold'}}>Phone:</label>
          <input type="text" className="form-control" value={phone} onChange={(e) => setPhone(e.target.value)} />
        </div>
        <div className="mb-3">
        <label htmlFor="field1" className="form-label" style={{fontStyle:'italic',fontWeight:'bold'}}>Title:</label>
          <input type="text" className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
        
        <div className="mb-3">
        <label htmlFor="field1" className="form-label" style={{fontStyle:'italic',fontWeight:'bold'}}>Email:</label>
          <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className="mb-3" style={{display:'none'}}>
        <label htmlFor="field1" className="form-label" style={{fontStyle:'italic',fontWeight:'bold'}}>Date of Birth:</label>
          <DatePicker style={{display:'unset'}} className="form-control" selected={dob} onChange={(date) => setDob(date)} dateFormat="yyyy-MM-dd" showMonthDropdown yearDropdownItemNumber={150} showYearDropdown  scrollableYearDropdown/>
        </div>
        <div className="mb-3">
        <label htmlFor="field1" className="form-label" style={{fontStyle:'italic',fontWeight:'bold'}}>Avatar:</label>
          <input type="file" className="form-control" accept="image/*" onChange={handleAvatarChange} />
        </div>
        {Slp && Slp.avatar && <img src={`/`+Slp.avatar.replace(/uploads\\/g, '')} alt="Avatar" className="topbaravatar img-fluid rounded-circle"/>}
        <div style={{float:'right'}}><button type="submit" className='btn' style={{background:'#41A66C',color:'white',width:'120px'}}>Save <img style={{width:'8px',float:'right',paddingTop:'6px'}} src={rarrow}/></button></div>
      </form>
    </div>



                        </div>
                    </div>
                </div>
              </div>




             
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
  );
};



const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile
});

export default connect(mapStateToProps, { getCurrentProfile, deleteAccount })(
    Profile
);
