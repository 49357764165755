import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getCurrentProfile, deleteAccount } from '../../actions/profile';
import SlpNavbar from '../../components/layout/SlpNavbar';
import Navbar from '../../components/layout/Navbar';
import imgfilter from '../../images/Filter.png';
import lesson1 from '../../images/IMG.png';
import lesson2 from '../../images/IMG1.png';
import lesson3 from '../../images/IMG2.png';
import lesson4 from '../../images/IMG3.png';
import iconlesson from '../../images/Icon-Lessons.png';
import { getLessons, setFilter, setPage } from '../../actions/slp';

const DashLessons = ({
  getCurrentProfile,
  deleteAccount,
  auth: { user },
  profile: { profile },
}) => {
  useEffect(() => {
    getCurrentProfile();
  }, [getCurrentProfile]);

  const [showTooltip, setShowTooltip] = useState(false);
  const dispatch = useDispatch();
  const { lessons } = useSelector((state) => state.slp);
  const [filterValue, setFilterValue] = useState('Active');

  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
  };

  useEffect(() => {
    dispatch(getLessons(filterValue));
  }, [dispatch, filterValue]);

  const divsPerPage = 10; // Number of divs per page
  const totalPages = Math.ceil(lessons.length / divsPerPage); // Calculate total pages

  const [currentPage, setCurrentPage] = useState(1);

  // Logic to get current divs based on pagination
  const indexOfLastDiv = currentPage * divsPerPage;
  const indexOfFirstDiv = indexOfLastDiv - divsPerPage;
  const currentDivs = lessons.slice(indexOfFirstDiv, indexOfLastDiv);

  // Logic to paginate
  const nextPage = (event) => {
    event.preventDefault();
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const prevPage = (event) => {
    event.preventDefault();
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const goToPage = (pageNumber,event) => {
    event.preventDefault();
    setCurrentPage(pageNumber);
  };

  const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(false);

    const updateMatches = () => {
      const mediaQuery = window.matchMedia(query);
      setMatches(mediaQuery.matches);
    };

    useEffect(() => {
      updateMatches();
      window.addEventListener('resize', updateMatches);

      return () => {
        window.removeEventListener('resize', updateMatches);
      };
    }, [query]);

    return matches;
  };

  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <div className='row'>
      <div className="row p-b-15">
        <div className='col-lg-10'><h4 style={{ fontWeight: 'bold', paddingLeft: '10px' }}>Lessons</h4></div>
        <div className='col'>
          <div className='row'>
            <div className='col-lg-2'><img style={{ width: '38px' }} src={imgfilter} /></div>
            <div className='col'>
              <select
                style={{ float: 'right', width: '135px', height: '32px', borderRadius: '5px', border: 'none', background: 'white', fontSize: '15px' }}
                id="filter"
                value={filterValue}
                onChange={handleFilterChange}
              >
                <option value="">Show All</option>
                <option value="tuffwords">Sort By: Hard Lesson</option>
                <option value="completed">Sort By: Done</option>
                <option value="incompleted">Sort By: Not Done</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      {isMobile ? (
        <div className="lessoncontainer">
          <div className="card-container" style={{ width: '95%' }}>
            {lessons.map((lesson, index) => (
              <div key={index} className="card" style={{ width: '90%' }}>
                <img src={lesson.subcatimage} alt="Header" className="card-header" style={{ height: 'auto' }}/>
                <div className="card-body">
                  <h5 className="card-title">{lesson.lessonname}</h5>
                  <h6 style={{ fontWeight: '600' }}>{lesson.subcategory}</h6>
                  <h6 className="card-text">
                    <img style={{ width: '15px' }} src={iconlesson} />
                    {lesson.studentReports.length > 0 ? lesson.words.split(",").length - lesson.studentReports[0].wordsspoken.length : '0'} / {lesson.words.split(",").length} words left
                  </h6>

                  {lesson.studentReports.length > 0 ? (
                    <div>
                      <div className="progress">
                        <div className="progress-bar progress-bar-success" role="progressbar" style={{ width: `${(lesson.studentReports[0].wordsspoken.length / lesson.words.split(",").length) * 100}%` }} aria-valuenow={lesson.studentReports[0].wordsspoken.length} aria-valuemin="0" aria-valuemax={lesson.words.split(",").length}></div>
                      </div>
                      <div className="tuff-words-container" onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
                        <div className="tuff-words-length">
                          {lesson.studentReports[0].tuffwords.length > 0 && (
                            <>
                              {lesson.studentReports[0].tuffwords.length} Hard words
                              {showTooltip && (
                                <div className="custom-tooltip">
                                  <span className="custom-tooltiptext">
                                    {lesson.studentReports[0].tuffwords.join(", ")}
                                  </span>
                                </div>
                              )}
                            </>
                          )}

                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>no student assigned</div>
                 
                  )}

                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="lessoncontainer">
            <div className="card-container">
              <div className="lessonrow lessonspl">
                {currentDivs.slice(0, 5).map((lesson) => (
                  <div className="card m-r-10" key={lesson.id}>
                    <img src={lesson.subcatimage} alt="Header" className="card-header" />
                    <div className="card-body">
                      <h5 className="card-title">{lesson.lessonname}</h5>
                      <h6 style={{ fontWeight: '600' }}>{lesson.subcategory}</h6>
                      <h6 className="card-text">
                        <img style={{ width: '15px' }} src={iconlesson} />
                        {lesson.studentReports.length > 0 ? lesson.words.split(",").length - lesson.studentReports[0].wordsspoken.length : '0'} / {lesson.words.split(",").length} words left
                      </h6>
  
                      {lesson.studentReports.length > 0 ? (
                        <div>
                          <div className="progress">
                            <div className="progress-bar progress-bar-success" role="progressbar" style={{ width: `${(lesson.studentReports[0].wordsspoken.length / lesson.words.split(",").length) * 100}%` }} aria-valuenow={lesson.studentReports[0].wordsspoken.length} aria-valuemin="0" aria-valuemax={lesson.words.split(",").length}></div>
                          </div>
                          <div className="tuff-words-container" onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
                            <div className="tuff-words-length">
                              {lesson.studentReports[0].tuffwords.length > 0 && (
                                <>
                                  {lesson.studentReports[0].tuffwords.length} Hard words
                                  {showTooltip && (
                                    <div className="custom-tooltip">
                                      <span className="custom-tooltiptext">
                                        {lesson.studentReports[0].tuffwords.join(", ")}
                                      </span>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div>no student assigned</div>
                      )}
  
                    </div>
                  </div>
                ))}
              </div>
              <div className="lessonrow lessonspl">
                {currentDivs.slice(5).map((lesson) => (
                  <div className="card m-r-10" key={lesson.id}>
                    <img src={lesson.subcatimage} alt="Header" className="card-header" />
                    <div className="card-body">
                      <h5 className="card-title">{lesson.lessonname}</h5>
                      <h6 style={{ fontWeight: '600' }}>{lesson.subcategory}</h6>
                      <h6 className="card-text">
                        <img style={{ width: '15px' }} src={iconlesson} />
                        {lesson.studentReports.length > 0 ? lesson.words.split(",").length - lesson.studentReports[0].wordsspoken.length : '0'} / {lesson.words.split(",").length} words left
                      </h6>
                      {lesson.studentReports.length > 0 ? (
                        <div>
                          <div className="progress">
                            <div className="progress-bar progress-bar-success" role="progressbar" style={{ width: `${(lesson.studentReports[0].wordsspoken.length / lesson.words.split(",").length) * 100}%` }} aria-valuenow={lesson.studentReports[0].wordsspoken.length} aria-valuemin="0" aria-valuemax={lesson.words.split(",").length}></div>
                          </div>
                          <div className="tuff-words-container" onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
                            <div className="tuff-words-length">
                              {lesson.studentReports[0].tuffwords.length > 0 && (
                                <>
                                  {lesson.studentReports[0].tuffwords.length} Hard words
                                  {showTooltip && (
                                    <div className="custom-tooltip">
                                      <span className="custom-tooltiptext">
                                        {lesson.studentReports[0].tuffwords.join(", ")}
                                      </span>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div>no student assigned</div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
  
        {!isMobile && (
          <div className="pagination">
            <button onClick={prevPage} disabled={currentPage === 1}>
              Previous
            </button>
            {Array.from({ length: totalPages }, (_, i) => (
              <button key={i} className={currentPage === i + 1 ? 'active' : ''} onClick={(e) => goToPage(i + 1, e)}>
                {i < 9 ? `0${i + 1}` : i + 1}
              </button>
            ))}
            <button onClick={nextPage} disabled={currentPage === totalPages}>
              Next
            </button>
          </div>
        )}
      </div>
    );
  };
  
  DashLessons.propTypes = {
    getCurrentProfile: PropTypes.func.isRequired,
    deleteAccount: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired
  };
  
  const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.profile
  });
  
  export default connect(mapStateToProps, { getCurrentProfile, deleteAccount })(
    DashLessons
  );
  