import React, { useEffect,useState } from 'react';
import { useParams } from 'react-router-dom';
import { logout,setIsOpen } from '../../actions/auth';
import logo from '../../images/tempo-talk-logo.svg';
import dashicon from '../../images/Icon-Dashboard.png';
import studenticon from '../../images/Icon-Students.png';

import signout from '../../images/sign-out.png';
import openclose from '../../images/oc.png';
import { getStudent } from '../../actions/students';
import teachericon from '../../images/Icon-Lessons.png';
import { connect,useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
const LeftNav = ({isOpen, setIsOpen,logout }) =>{
  

  const dispatch = useDispatch();
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsOpen(false);
    } 
  };


  const [showComponent, setShowComponent] = useState(false);
  const { id } = useParams();
  
    const student = useSelector((state) => state.student.students);

    useEffect(() => {
      if (id) {
        setShowComponent(true);
      } else {
        setShowComponent(false);
      }
      dispatch(getStudent(id));
      handleResize();
    }, [dispatch]); 

  const currentPath = window.location.pathname;
  return (
    <div className={`left-nav ${isOpen ? 'open' : 'closed'}`}>
           
    <div className="sidebar-header">
    <div><img  src={logo} alt="logo" className='tempologo'/></div>
    <img
          src={openclose} // Use the imported openclose image
          alt="Toggle Menu"
          className={`${isOpen ? 'toggle-img' : 'togtoggle-img'}`}
          onClick={toggleMenu}
        />
    </div>
    <ul className={`sidebar-menu accordion-menu ${isOpen ? ' p-t-100' : ' '}`}>
      <li className={currentPath.startsWith('/dashboard') ? 'active' : ''} ><a href='/dashboard'><img className='menuicon' src={dashicon} alt="dashicon" /><span className="hide-sm">Dashboard</span></a></li>
      <li className={currentPath.startsWith('/admin/teachers') ? 'active' : ''}><a  href="/admin/teachers"><img className='menuicon' src={teachericon} alt="teachers" /><span className="hide-sm">Teachers</span></a></li>
      
      <li className={currentPath.startsWith('/admin/students') ? 'active' : ''}><a href='/admin/students'><img className='menuicon' src={studenticon} alt="studenticon" /><input type="checkbox" id="toggle1"/>
    <span className="hide-sm">Students</span></a>
    {showComponent &&
    <ul className="submenu acc" style={{padding:'padding: 15px'}}>
      <li style={{padding:'6px 0 10px 28px !important;'}}><i className="fas fa-chevron-right"/>{' '}{student.stuName}</li>
    </ul>}</li>
      
     
    <li className='btop'>
          <a onClick={logout} href="#!">
            <img src={signout} className='menuicon' style={{marginRight:'20px'}}/>{' '}
            <span className="hide-sm">Logout</span>
          </a>
        </li>
      {/* You can add more items here */}
      

   



    </ul>
  </div>
  );
}


LeftNav.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  setIsOpen: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  isOpen: state.auth.isOpen !== undefined ? state.auth.isOpen : true,
});

export default connect(mapStateToProps, { logout,setIsOpen  })(LeftNav);