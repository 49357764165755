import {
    ADD_PARENT_SUCCESS,
    ADD_PARENT_FAILURE,
    GET_PARENT_SUCCESS,
    GET_PARENT_FAILURE,
    FETCH_PARENT_REQUEST,
    GET_PARENT_PROFILE,
    FETCH_STUDENT_REQUEST,
    FETCH_STUDENT_SUCCESS,
    FETCH_STUDENT_FAILURE,
    UPDATE_STUDENT_SUCCESS,
    UPDATE_STUDENT_FAILURE
  } from '../actions/types';

const initialState = {
    loading: false,
    error: null,
    data: null,
    parent: null,
    student:[],
    lesson:[],
    slp:[],
    estudent:null,
  };
  
  const parentReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADD_PARENT_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          parent: action.payload
        };
      case ADD_PARENT_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
          parent: null
        };
      case FETCH_PARENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
      case GET_PARENT_SUCCESS:
        return {
          ...state,
          parent: action.payload,
          loading: false,
          error: null
        };
        case GET_PARENT_PROFILE:
        return {
          ...state,
          parent: action.payload.parent,
          student: action.payload.students,
          lesson: action.payload.lessons,
          slp: action.payload.slps,
          loading: false,
          error: null
        };
        case GET_PARENT_FAILURE:
          return {
            ...state,
            parent: null,
            loading: false,
            error: action.payload
          };
      case FETCH_STUDENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
      case FETCH_STUDENT_SUCCESS:
      return {
        ...state,
        loading: false,
        estudent: action.payload,
      };
      case UPDATE_STUDENT_SUCCESS:
      return {
        ...state,
        loading: false,
        estudent: action.payload,
      };
    case UPDATE_STUDENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case FETCH_STUDENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
   
    
      default:
        return state;
    }
  };
  export default parentReducer;