import React, { useState } from 'react';
import {ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const HardWordChart = () => {
  const [timeRange, setTimeRange] = useState('monthly');

  const handleButtonClick = (range) => {
    setTimeRange(range);
  };

  const getDataForTimeRange = () => {
    let data = [];

    switch (timeRange) {
      case 'monthly':
        data = [
          { name: 'Jan', 'Hard Words': 100, 'Words': 150 },
          { name: 'Feb', 'Hard Words': 120, 'Words': 180 },
          { name: 'Mar', 'Hard Words': 90, 'Words': 200 },
          { name: 'Apr', 'Hard Words': 110, 'Words': 190 },
          { name: 'May', 'Hard Words': 80, 'Words': 220 },
          { name: 'Jun', 'Hard Words': 70, 'Words': 210 },
          { name: 'Jul', 'Hard Words': 100, 'Words': 230 },
          { name: 'Aug', 'Hard Words': 120, 'Words': 250 },
          { name: 'Sep', 'Hard Words': 90, 'Words': 270 },
          { name: 'Oct', 'Hard Words': 110, 'Words': 260 },
          { name: 'Nov', 'Hard Words': 130, 'Words': 290 },
          { name: 'Dec', 'Hard Words': 150, 'Words': 300 },
        ];
        break;
      case 'quarterly':
        data = [
          { name: 'Q1', 'Hard Words': 320, 'Words': 500 },
          { name: 'Q2', 'Hard Words': 280, 'Words': 550 },
          { name: 'Q3', 'Hard Words': 400, 'Words': 700 },
          { name: 'Q4', 'Hard Words': 350, 'Words': 600 },
        ];
        break;
      case 'weekly':
        data = [
          { name: 'Week 1', 'Hard Words': 50, 'Words': 80 },
          { name: 'Week 2', 'Hard Words': 70, 'Words': 100 },
          // Add data for other weeks
        ];
        break;
      default:
        // Return original data for yearly range
        data = [
          { name: '2024', 'Hard Words': 500, 'Words': 800 },
       
        ];
        break;
    }

    // Ensure that "Words" is always higher than "Hard Words"
    data.forEach(item => {
      if (item['Words'] < item['Hard Words']) {
        item['Words'] = item['Hard Words'] + 100; // Adjust "Words" to be higher than "Hard Words"
      }
    });

    return data;
  };

  return (
    <div>
      <div className='row p-b-10'>
        <div className='col-lg-8' style={{color:'#75e485',fontWeight:'bold'}}>Word Analytics Comparison ({timeRange})</div>
        <div className='col' style={{textAlign:'right'}}>
          <a href="#" onClick={() => handleButtonClick('weekly')} style={{display:'none',fontSize:'14px',textDecoration:'none',color: timeRange === 'weekly' ? '#75e485' : '#B2B7C1'}}>Weekly</a>
          &nbsp;
          <a href="#" onClick={() => handleButtonClick('monthly')} style={{fontSize:'14px',textDecoration:'none',color: timeRange === 'monthly' ? '#75e485' : '#B2B7C1'}}>Monthly</a>
          &nbsp;
          <a href="#" onClick={() => handleButtonClick('quarterly')} style={{fontSize:'14px',textDecoration:'none',color: timeRange === 'quarterly' ? '#75e485' : '#B2B7C1'}}>Quarterly</a>
          &nbsp;
          <a href="#" onClick={() => handleButtonClick('yearly')} style={{fontSize:'14px',textDecoration:'none',color: timeRange === 'yearly' ? '#75e485' : '#B2B7C1'}}>Yearly</a>
        </div>
      </div>
      
      <BarChartComponent data={getDataForTimeRange()} />
    </div>
  );
};

const BarChartComponent = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
    <BarChart data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="Words" fill="#660090" />
      <Bar dataKey="Hard Words" fill="#32D74B" />
    </BarChart>
    </ResponsiveContainer>
  );
};

export default HardWordChart;
