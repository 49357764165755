import api from '../utils/api';
import {
 ADD_STUDENT,
 GET_STUDENT,
 ADD_STUDENT_FAILURE,
 GET_STUDENTS_FAILURE,
 GET_STUDENTS_SUCCESS,
 GET_STUDENTS_REQUEST,
 SET_STUDENT_STATUS,
 FETCH_STUDENT_COUNT_SUCCESS,
 FETCH_STUDENT_COUNT_FAILURE,
 FETCH_ACTIVE_STUDENT_COUNT_SUCCESS,
 DELETE_STUDENT_SUCCESS,
 ADD_PARENT_SUCCESS,
 ADD_PARENT_FAILURE,
 GET_PARENT_SUCCESS,
 GET_PARENT_FAILURE,
 FETCH_PARENT_REQUEST,
 GET_SLP_SUCCESS,
 GET_SLP_FAILURE,
 GET_REPORT_SUCCESS,
 GET_REPORT_FAILURE,
 SET_FILTER,
 SET_PAGE,
} from './types';

/*
  NOTE: we don't need a config object for axios as the
 default headers in axios are already Content-Type: application/json
 also axios stringifies and parses JSON for you, so no need for 
 JSON.stringify or JSON.parse
*/

// Get students
// Action creator for getting students
// Action creator for getting students
export const getGridStudents = () => async (dispatch) => {
  try {
    dispatch({ type: GET_STUDENTS_REQUEST });
    
    const url = '/students/students';
    const res = await api.get(url);
    const { students, totalPages } = res.data;

      // Check if totalPages is defined
      if (totalPages) {
        dispatch({ type: GET_STUDENTS_SUCCESS, payload: { students, totalPages } });
      } else {
        // If totalPages is undefined, it means non-paginated response
        dispatch({ type: FETCH_STUDENTS_SUCCESS, payload: { students } });
      }
    } catch (error) {
      dispatch({ type: GET_STUDENTS_FAILURE, payload: error.message });
    }
};

export const getStudents = (filter, page) => {
  return async (dispatch) => {
    dispatch({ type: GET_STUDENTS_REQUEST });

    try {
      const response = await api.get(`/students/cardstudents?filter=${filter}&page=${page}`);
      const { students, totalPages } = response.data; 
      console.log(students);
      dispatch({ type: GET_STUDENTS_SUCCESS, payload: { students, totalPages } });
    } catch (error) {
      dispatch({ type: GET_STUDENTS_FAILURE, payload: error.message });
    }
  };
};

export const getDashStudents = (filter, limit) => async (dispatch) => {
  try {
    dispatch({ type: GET_STUDENTS_REQUEST });
    const url = `/students/dashstudents?filter=${filter}&limit=${limit}`;
    const res = await api.get(url);
    const { students, totalPages } = res.data;

      if (totalPages) {
        dispatch({ type: GET_STUDENTS_SUCCESS, payload: { students, totalPages } });
      } else {
       
        dispatch({ type: FETCH_STUDENTS_SUCCESS, payload: { students } });
      }
    } catch (error) {
      dispatch({ type: GET_STUDENTS_FAILURE, payload: error.message });
    }
};

// Add student
export const slpaddStudent = (formData,onStudentAdded) => async (dispatch) => {
  try {
    const res = await api.post('/students', formData);
    dispatch({
      type: ADD_STUDENT,
      payload: res.data
    });
    if (onStudentAdded && typeof onStudentAdded === 'function') {
      onStudentAdded();
    }
  }catch (err) {
    dispatch({
      type: ADD_STUDENT_FAILURE,
      payload: err.response.data.message || 'Server Error'
    });
    
    // Show error alert
    alert('Failed to add student. Please try again.');
  }
};

// Get student
export const getStudent = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/students/students/${id}`);

    dispatch({
      type: GET_STUDENT,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: ADD_STUDENT_FAILURE,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const setStudentStatus = (id, status) => {
  return async (dispatch) => {
    try {
      const response = await api.put(`/students/students/${id}/status`, { status });
      if (response.data.success) {
        dispatch({ type: SET_STUDENT_STATUS, payload: { id, status } });
      }
    } catch (error) {
      console.error('Error setting student status:', error);
    }
  };
};

export const fetchStudentCount = () => {
  return async (dispatch) => {
    try {
      const response = await api.get('/students/count');
      dispatch({ type: FETCH_STUDENT_COUNT_SUCCESS, payload: response.data.count });
    } catch (error) {
      console.error('Error fetching student count:', error.message);
      dispatch({ type: FETCH_STUDENT_COUNT_FAILURE });
    }
  };
};

export const fetchStudentActiveCount = () => {
  return async (dispatch) => {
    try {
      const response = await api.get('/students/activecount');
      dispatch({ type: FETCH_ACTIVE_STUDENT_COUNT_SUCCESS, payload: response.data.count });
    } catch (error) {
      console.error('Error fetching student count:', error.message);
     
    }
  };
};

export const deleteStudentById = (id) => {
  return async (dispatch) => {
    try {
      await api.delete(`/students/${id}`);
      dispatch({ type: 'DELETE_STUDENT_SUCCESS', payload: id });
    } catch (error) {
      console.error('Error deleting student:', error.message);
      // Handle error if needed
    }
  };
};

export const addParent = (parentData) => {
  return async (dispatch) => {
    try {
      const response = await api.post('/students/parents', parentData);
      dispatch({ type: ADD_PARENT_SUCCESS, payload: response.data });
      return response.data; // Return the data for successful response
    } catch (error) {
      dispatch({ type: ADD_PARENT_FAILURE, payload: error.message });
      throw error; // Rethrow the error to handle it in the component
    }
  };
};


export const fetchParent = (phone) => {
  return async (dispatch) => {
    try {
      const response = await api.get(`/students/searchparents/${phone}`);
      const parent = response.data[0];
      dispatch({ type: GET_PARENT_SUCCESS, payload: parent });
    } catch (error) {
      dispatch({ type: GET_PARENT_FAILURE, payload: error.message });
    }
  };
};

export const fetchSlp = () => {
  return async (dispatch) => {
    try {
      const response = await api.get('/students/slp');
     
      dispatch({ type: GET_SLP_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: GET_SLP_FAILURE, payload: error.message });
    }
  };
};

export const fetchStuReport = (id) => {
  return async (dispatch) => {
    try {
      const response = await api.get(`/students/getstureport/${id}`);
     
      dispatch({ type: GET_REPORT_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: GET_REPORT_FAILURE, payload: error.message });
    }
  };
};

export const setFilter = (filter) => ({
  type: SET_FILTER,
  payload: filter,
});

export const setPage = (page) => ({
  type: SET_PAGE,
  payload: page,
});
