import React, { useState } from 'react';
import {ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

// Original data for the BarChartComponent
const chartData = [
  { name: '2024', 'Registration': 15},
  { name: '2025', 'Registration': 0},
  { name: '2026', 'Registration': 0},
];

const TotalStuChartComponent = () => {
  const [timeRange, setTimeRange] = useState('monthly');

  const handleButtonClick = (range) => {
    setTimeRange(range);
  };

  const getDataForTimeRange = () => {
    switch (timeRange) {
      case 'monthly':
        // Return data for monthly range
        return [
          { name: 'Jan', 'Registration': 0},
          { name: 'Feb', 'Registration': 0},
          { name: 'Mar', 'Registration': 0},
          { name: 'Apr', 'Registration': 0},
          { name: 'May', 'Registration': 15},
          { name: 'Jun', 'Registration': 0},
         
        ];
      case 'quarterly':
        // Return data for quarterly range
        return [
          { name: 'Q1', 'Registration': 23},
          { name: 'Q2', 'Registration': 6},
          { name: 'Q3', 'Registration': 0},
          { name: 'Q4', 'Registration': 0},
        ];
      default:
        // Return original data for yearly range
        return chartData;
    }
  };

  return (
    <div>
      <div className='row p-b-10'>
                            <div className='col-lg-8' style={{color:'#75e485',fontWeight:'bold'}}>overall student registration (<span id="clickedValue">{timeRange}</span>)</div>
                            <div className='col' style={{textAlign:'right'}}>
                            <a href="#" onClick={() => handleButtonClick('monthly')} style={{fontSize:'14px',textDecoration:'none',color: timeRange === 'monthly' ? '#75e485' : '#B2B7C1'}}>Monthly</a>
                            &nbsp;
      <a href="#" onClick={() => handleButtonClick('quarterly')} style={{color:'#B2B7C1',fontSize:'14px',textDecoration:'none',color: timeRange === 'quarterly' ? '#75e485' : '#B2B7C1'}}>Quarterly</a>
      &nbsp;
      <a href="#" onClick={() => handleButtonClick('yearly')} style={{color:'#B2B7C1',fontSize:'14px',textDecoration:'none',color: timeRange === 'yearly' ? '#75e485' : '#B2B7C1'}}>Yearly</a>
                            </div>
                          </div>
      
      {/* Conditional rendering of BarChartComponent based on the selected time range */}
      {timeRange === 'yearly' && <BarChartComponent data={getDataForTimeRange()} />}
      {timeRange === 'monthly' && <BarChartComponent data={getDataForTimeRange()} />}
      {timeRange === 'quarterly' && <BarChartComponent data={getDataForTimeRange()} />}
    </div>
  );
};

const BarChartComponent = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
    <BarChart data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      
      <Bar dataKey="Registration" fill="#32D74B" />
    </BarChart>
    </ResponsiveContainer>
  );
};

export default TotalStuChartComponent;
