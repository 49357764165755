import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import profile from './profile';
import post from './post';
import student from './student';
import slp from './slp';
import parent from './parent';
import admin from './admin';
export default combineReducers({
  alert,
  auth,
  profile,
  post,
  student,
  slp,
  parent,
  admin
});
