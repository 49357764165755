import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';
import logo from '../../images/tempo-talk-logo.svg';
import Illustration from '../../images/Illustration.svg';
import sdtrw from '../../images/sdtrw.svg';
const Login = ({ login, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const { email, password } = formData;
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    try {
      const response = await login(email, password);
      setLoading(false);
      if (response.success) {
        setMessage('Login successful. Redirecting...');
       // history.push('/dashboard');
      } else {
        setMessage(response.error || 'Login failed. Please try again.');
      }
    } catch (error) {
      setLoading(false);
      setMessage('An error occurred. Please try again later.');
      console.error('Error signing in:', error);
    }
  };



  
  if (isAuthenticated) {
    return <Navigate to="/slp/dashboard" />;
  }

  return (
    <section>
     
     {loading && (
              <div className="overlay d-flex justify-content-center align-items-center">
              <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
              </div>
          </div>
            )}
     <div className="container-fluid">
    <div className="row">
      <div className="col-md-6 loginleft">
        <div className='m-l-140 m-t-10'>
        <div><img  src={logo} alt="logo" /></div>
        <div><img src={sdtrw} alt="sdtrw" /></div>
        </div>
        <div className="custom-contentLeft">
        
        <img  src={Illustration} alt="Illustration" />
        </div>
      </div>
      <div className="col-md-6">
        <div className="custom-contentRight">
        {message && <div className={`alert ${message.startsWith('Login successful') ? 'alert-success' : 'alert-danger'}`}>{message}</div>}
        <p className="m-l-10"><b>Sign In</b></p>
      
      <form className="form-horizontal" onSubmit={onSubmit}>
      
        <div className="form-group mb-3">
        <div className="col-12">
        <label className="form-label">Email</label>
          <input
          className='form-control'
            type="email"
            placeholder="input your email in here"
            name="email"
            value={email}
            onChange={onChange}
          />
          </div>
        </div>
        <div className="form-group mb-3">
        <div className="col-12">
        <label className="form-label"> Password</label>
          <input
          className='form-control'
            type="password"
            placeholder="input your password in here"
            name="password"
            value={password}
            onChange={onChange}
            minLength="6"
          />
          <div className='forpass'><Link to="/">Forgot password?</Link> </div>
          </div>
        </div>
        <div className="form-group text-center m-t-40">
        <div className="col-12">
        <input type="submit" className="btn btn-primary w-100 waves-effect waves-light" value="Sign in" />
        <div className='nhelp m-t-10'>Need help? Contact Us! <Link to="/">Get Help</Link> </div>
        </div>
        </div>
        
      </form>
      
        </div>
      </div>
    </div>
  </div>


      
    </section>
    
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { login })(Login);
