import React, { useEffect,useState } from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { connect,useDispatch,useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCurrentProfile, deleteAccount } from '../../actions/profile';
import ParentLeftNav from '../../components/layout/ParentLeftNav';
import Navbar from '../../components/layout/Navbar';
import { getParent,fetchStudentById,updateStudent, updateParent} from '../../actions/parent';
import { getStudent, fetchStuReport } from '../../actions/students';
import man1 from '../../images/man1.png';
import man2 from '../../images/man2.png';
import man3 from '../../images/fem3.png';
import man4 from '../../images/user-male.png';
import pupload from '../../images/upload.png';
import 'react-datepicker/dist/react-datepicker.css';
import cardback from '../../images/tback.png';
import imgstudent from '../../images/student2.png';
import rstar from '../../images/video-lesson1.png';
const EditProfile = ({
  getCurrentProfile,
  deleteAccount,
  isOpen,
  auth: { user },
  profile: { profile }
}) => {
  useEffect(() => {
    getCurrentProfile();
  }, [getCurrentProfile]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getParent(user.user.email));
  }, [dispatch, user.user.email]);

  const { stuid } = useParams();
  const {estudent, student, error, filter, page,parent } = useSelector((state) => state.parent);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    dispatch(fetchStudentById(stuid));
  }, [dispatch,stuid]);


  const { students, stuReport } = useSelector((state) => state.student);
  useEffect(() => {
    if (stuid) {
      dispatch(getStudent(stuid));
      dispatch(fetchStuReport(stuid));
    }
  }, [dispatch, stuid]);



const [studentFormData, setStudentFormData] = useState({
    studentName: estudent?.stuName || '',
    studentGrade: estudent?.stuGrade || '',
    studentDob:estudent?.dob||'',
    avatar:estudent?.avatar||'',
    // Add more student form fields as needed
  });

  // Initialize state for parent form with pre-filled values
  const [parentFormData, setParentFormData] = useState({
    parentName: parent?.parentName || '',
    parentEmail: parent?.parentEmail || '',
    parentPhone: parent?.parentPhone || '',
    parentId: parent?._id || '',
    avatar:parent?.avatar||'',
    // Add more parent form fields as needed
  });


  useEffect(() => {
    if (estudent) {
      setStudentFormData({
        studentName: estudent.stuName,
        studentGrade: estudent.stuGrade,
        studentDob:estudent.dob,
        avatar:estudent.avatar,
        // Add more student form fields as needed
      });
    }
    if (parent) {
      setParentFormData({
        parentName: parent.parentName,
        parentEmail: parent.parentEmail,
        parentPhone: parent.parentPhone,
        pid:parent._id,
        avatar:parent.avatar,
        // Add more parent form fields as needed
      });
    }
  }, [estudent, parent]);
  // State for student profile image
  

  // State for parent profile image
  const [parentProfileImage, setParentProfileImage] = useState(null);
 
  const [studentProfileImageUrl, setStudentProfileImageUrl] = useState('');
  const handleStudentInputChange = (e) => {
    setStudentFormData({
      ...studentFormData,
      [e.target.name]: e.target.value
    });
  };
  

  const handleParentInputChange = (e) => {
    setParentFormData({
      ...parentFormData,
      [e.target.name]: e.target.value
    });
  };

  const handleStudentImageChange = (e) => {
    setStudentProfileImageUrl(e.target.files[0]);
  };
  

  const handleParentImageChange = (e) => {
    setParentProfileImage(e.target.files[0]);
  };

  const handleStudentFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Prepare student data object
      const studentData = {
        ...studentFormData,
        // Assuming you want to send the image data URL
        // If you want to send the file directly, handle it accordingly
        profileImage: studentProfileImageUrl,
      };
  
      // Dispatch the updateStudent action with the student data
       dispatch(updateStudent(stuid, studentData));
  
      // Optionally, you can handle success cases here (e.g., show a success message)
      
      

      const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));
(async () => {
    await wait(2000); 
    setLoading(false);
    alert('Student information updated successfully');
    console.log("Waited for 3 seconds!");
})();

    } catch (error) {
      // Handle errors (e.g., show an error message)
      console.error('Error updating student information:', error.message);
    }
  };

  

  const handleParentFormSubmit = (e) => {setLoading(true);
    e.preventDefault();
    // Handle form submission for updating parent information
    console.log("Parent Form Data:", parentFormData);
    console.log("Parent Profile Image:", parentProfileImage);

    e.preventDefault();
  
    try {
     
      const parentData = {
        ...parentFormData,
       
        profileImage: parentProfileImage,
      };
       
       dispatch(updateParent(parentData));
  
    const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));
(async () => {
    await wait(2000); 
    setLoading(false);
    alert('Parent information updated successfully');
    window.location.reload();
   
})();
    } catch (error) {
      // Handle errors (e.g., show an error message)
      console.error('Error updating student information:', error.message);
    }


  };
  const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(false);

    const updateMatches = () => {
      const mediaQuery = window.matchMedia(query);
      setMatches(mediaQuery.matches);
    };

    useEffect(() => {
      updateMatches();
      window.addEventListener('resize', updateMatches);

      return () => {
        window.removeEventListener('resize', updateMatches);
      };
    }, [query]);

    return matches;
  };

  const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    <section>
      <div className="App">
      {loading && (
              <div className="overlay d-flex justify-content-center align-items-center">
              <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
              </div>
          </div>
            )}
      <div className="container-fluid">
        <div className="row">
          
          {/* Left Navigation Sidebar */}
          <div className="col-md-3">
            <ParentLeftNav/>
          </div>

          {/* Main Content */}
          <div className={`col-md-12 ${isOpen ? 'content' : 'togcontent'}`}>
          <Navbar/>
            <div className={`p-20 ${isOpen ? 'innercontent' : 'toginnercontent'}`} style={{height:'100vh'}}>
           
        <div className='row'>
        <div className='col-lg-12'>

                <div className='row'>
                    <div className={`card col-lg-${isMobile ? '12' : '4'} m-r-15`}>
                        <div className='card-body'>
                        
                        <div>
       
        <form onSubmit={handleParentFormSubmit}>
          {/* Parent Profile Image Upload */}
          <div style={{margin:'0 auto', textAlign:'center'}}>
            
            <input type="file" id="parent-image" onChange={handleParentImageChange} style={{display:'none'}}/>
            <img src={pupload} style={{ cursor: 'pointer',width:'20px',position:'absolute',left:'60%',top:'20%' }} onClick={() => document.getElementById('parent-image').click()}/>
            
            {parentFormData && parentFormData.avatar ? (
  <img 
    src={`/${parentFormData.avatar.replace(/uploads\\/g, '')}`} 
    alt="Avatar" 
    style={{ width: '100px', height: '100px', borderRadius: '50%' }}
  />
) : (
  <img 
    src={man1} 
    alt="Student Profile" 
    style={{ width: '100px', height: '100px', borderRadius: '50%' }} 
  />
)}
            
          </div>
          <h6><strong>Parent Information</strong></h6>
          {/* Parent Name Input */}
          <div>
          <input type="hidden" id="pid" className='form-control m-b-5' name="pid" value={parentFormData.pid} />
            <input type="text"  id="parentName" className='form-control m-b-5' name="parentName" value={parentFormData.parentName} onChange={handleParentInputChange} />
          </div>
          
          {/* Parent Email Input */}
          <div>
            
            <input type="email" disabled id="parentEmail" className='form-control m-b-5' name="parentEmail" value={parentFormData.parentEmail} onChange={handleParentInputChange} />
          </div>
          
          {/* Parent Phone Input */}
          <div>
          
            <input type="text" disabled id="parentPhone" className='form-control m-b-5' name="parentPhone" value={parentFormData.parentPhone} onChange={handleParentInputChange} />
          </div>
          
          {/* Add more parent form fields as needed */}
          
          <button type="submit" className='btn' style={{padding: '10px 160px',marginTop:'30px',backgroundColor: '#41A66C', 'background-color': '#41A66C !important',color:'white'}}>Save</button>
        </form>
      </div>
                        </div>
                    </div>
                    <div className={`card col-lg-${isMobile ? '12' : '4'} m-r-15`}>
                        <div className='card-body'>

                        <div>
        
        <form onSubmit={handleStudentFormSubmit}>
          {/* Student Profile Image Upload */}
          <div style={{margin:'0 auto',textAlign:'center'}}>
           
            <input type="file" id="student-image" onChange={handleStudentImageChange} style={{ display: 'none' }}/>
            <img src={pupload} style={{ cursor: 'pointer',width:'20px',position:'absolute',left:'60%',top:'20%' }} onClick={() => document.getElementById('student-image').click()}/>
            {studentFormData && studentFormData.avatar ? (
  <img 
    src={`/${studentFormData.avatar.replace(/uploads\\/g, '')}`} 
    alt="Avatar" 
    style={{ width: '100px', height: '100px', borderRadius: '50%' }}
  />
) : (
  <img 
    src={man2} 
    alt="Student Profile" 
    style={{ width: '100px', height: '100px', borderRadius: '50%' }} 
  />
)}
              </div>

          <h6><strong>Student Information</strong></h6>
          <div>
            
            <input type="text" id="studentName" className='form-control m-b-5' name="studentName" value={studentFormData.studentName} onChange={handleStudentInputChange} />
          </div>
          
          {/* Student Grade Input */}
          <div>
           
            <input type="text" id="studentGrade" className='form-control m-b-5' name="studentGrade" value={studentFormData.studentGrade} onChange={handleStudentInputChange} />
          </div>
          <div style={{display:'none'}}>
            
            <DatePicker
        id="studentDob"
        selected={studentFormData.studentDob} // Use studentDob from studentFormData
        onChange={(date) => setStudentFormData({ ...studentFormData, studentDob: date })} // Update studentDob in studentFormData
        dateFormat="yyyy-MM-dd"
        showMonthDropdown
        yearDropdownItemNumber={150}
        showYearDropdown
        scrollableYearDropdown
        className='form-control m-b-5'
      />
        </div>
          
          <button type="submit" className='btn' style={{padding: '10px 160px',marginTop:'30px',backgroundColor: '#41A66C', 'background-color': '#41A66C !important',color:'white'}}>Save</button>
        </form>
      </div>
                       
                        </div>
                    </div>
                   

                    <div className='col'>
                      {students.slps ? (
                        students.slps.map((slp, index) => (
                          <div className="col m-b-10" key={index}>
                            <div className="flip-card stuprofile">
                              <div className="flip-card-front" style={{ borderRadius: '10px' }}>
                                <div className="card-header" style={{ backgroundImage: `url(${cardback})`, height: '128px', width: '100%', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
                                  {slp && slp.avatar && <img src={`/` + slp.avatar.replace(/uploads\\/g, '')} alt="Avatar" className="avatar" style={{ background: "#f3de94", border: 'none' }} />}
                                </div>
                                <h5 style={{ fontWeight: 'bold', fontSize: '16px', marginTop: '20px' }}>{slp.firstname + " " + slp.lastname}</h5>
                                <div className="card-body" style={{ textAlign: 'center', color: '#838383' }}>
                                  <div>{slp.title}</div>
                                  <div>{slp.email}</div>
                                  <div>{slp.phone}</div>
                                  <div class="border m-t-30 rounded">
                                    <img className='p-t-10' src={rstar} style={{ width: '20px' }} />
                                    <div><h7 style={{ fontWeight: 'bold' }}>Rattings</h7></div>
                                    <div className='rounded m-15' style={{ background: '#EDEDED', padding: '3px 40px' }}>{slp.ratting ? slp.ratting : 'No Rattings Found'}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p>Loading...</p>
                      )}
                    </div>

                        </div>
                       
              



        </div>
        </div>
               
             

            </div>
          </div>
        </div>
      </div>
    </div>
    
    </section>
  );
};

EditProfile.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  isOpen: state.auth.isOpen !== undefined ? state.auth.isOpen : true, 
});

export default connect(mapStateToProps, { getCurrentProfile, deleteAccount })(
    EditProfile
);
