import {
    GET_STUDENTS,
    ADD_STUDENT,
    ADD_STUDENT_FAILURE,
    GET_STUDENTS_SUCCESS,
    FETCH_STUDENTS_SUCCESS,
    GET_STUDENTS_FAILURE,
    GET_STUDENTS_REQUEST,
    GET_STUDENT,
    SET_STUDENT_STATUS,
    FETCH_STUDENT_COUNT_SUCCESS,
    FETCH_STUDENT_COUNT_FAILURE,
    FETCH_ACTIVE_STUDENT_COUNT_SUCCESS,
    DELETE_STUDENT_SUCCESS,
    GET_SLP_SUCCESS,
    GET_SLP_FAILURE,
    GET_REPORT_SUCCESS,
    SET_FILTER,
    SET_PAGE,
  } from '../actions/types';
  
  const initialState = {
    students: [],
    student:null,
    loading: false,
    error: null,
    filter: 'active', 
    page: 1,
    totalPages: 1,
    slps:[],
    stuReport:[],
  };
  
  const studentReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_STUDENTS:
        return {
            ...state,
            loading: true,
            students: action.payload,
            error: null
        };
        case GET_STUDENT:
          return {
              ...state,
              loading: true,
              students: action.payload,
              error: null
          };
      case GET_STUDENTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
      case GET_STUDENTS_SUCCESS:
        return {
            ...state,
            loading: false,
            students: action.payload.students,
            totalPages: action.payload.totalPages
        };
        case FETCH_STUDENTS_SUCCESS:
          return {
            ...state,
            loading: false,
            students: action.payload.students,
            totalPages: 1
        };
      case GET_STUDENTS_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload
        };
      case ADD_STUDENT:
        return {
          ...state,
          loading: false,
          students: [action.payload, ...state.students],
        };
       
        case DELETE_STUDENT_SUCCESS:
        return {
          ...state,
          loading: false,
          students: [action.payload, ...state.students],
        };
        case SET_FILTER:
          return { ...state, filter: action.payload, page: 1 };
        case SET_PAGE:
          return { ...state, page: action.payload };
      case ADD_STUDENT_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      case SET_STUDENT_STATUS:
          return {
            ...state,
            students: state.students.map(student =>
              student.id === action.payload.id ? { ...student, status: action.payload.status } : student
            )
          };
          case FETCH_STUDENT_COUNT_SUCCESS:
            return {
              ...state,
              studentCount: action.payload,
              loading: false,
              error: null
            };
          case FETCH_STUDENT_COUNT_FAILURE:
            return {
              ...state,
              loading: false,
              error: 'Failed to fetch student count'
            };
        case FETCH_ACTIVE_STUDENT_COUNT_SUCCESS:
          return {
            ...state,
            studentActiveCount: action.payload,
            loading: false,
            error: null
          };
          case GET_SLP_SUCCESS:
          return {
            ...state,
            loading: false,
            slps: action.payload,
         
        };
        case GET_REPORT_SUCCESS:
          return {
            ...state,
            loading: false,
            stuReport: action.payload,
         
        };
        case GET_SLP_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default studentReducer;
  