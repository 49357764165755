import React, { Fragment,useEffect,useState   } from 'react';
import { Link } from 'react-router-dom';
import { connect,useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';
import { getCurrentProfile } from '../../actions/profile';
import fem3 from '../../images/fem3.png';
import headsetting from '../../images/headersetting.png';
import { getSlp } from '../../actions/slp';
const Navbar = ({ auth: { user }, logout,getCurrentProfile}) => {
  const dispatch = useDispatch();
  const slp = useSelector(state => state.slp);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        await user; // Fetch user data
        if (user && user.user.id) {
          await dispatch(getSlp(user.user.id)); // Fetch SLP data using user id
        }
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false); // Set loading to false in case of error
      }
    };

    fetchData();
  }, [dispatch, user]); // Dependency on user to trigger fetchSlp when user data changes

  

  if (loading) {
    return <div>Loading...</div>;
  }
  console.log(user);
  const authLinks = (
    <ul>
      <li>
        
      </li>
      <li>
        <Link to="/posts">Posts</Link>
      </li>
      <li>
        <Link to="/dashboard">
          <i className="fas fa-user" />{' '}
          <span className="hide-sm">Dashboard</span>
        </Link>
      </li>
      <li>
        <a onClick={logout} href="#!">
          <i className="fas fa-sign-out-alt" />{' '}
          <span className="hide-sm">Logout</span>
        </a>
      </li>
    </ul>
  );

  const guestLinks = (
    <ul>
      <li>
        <Link to="/profiles">Developers</Link>
      </li>
      <li>
        <Link to="/register">Register</Link>
      </li>
      <li>
        <Link to="/login">Login</Link>
      </li>
    </ul>
  );
  
  const usercurrName = user && user.user ? user.user.CurruserName : '';
  return (
    
    <nav className="navbar bg-white">
   
      <div className="row no-gutters">
     <div className="col">
     <div class="search-container">
    <input type="text" class="search-box" placeholder="Search..."/>
</div>
     </div>
     
     </div>
     
      
      <div><Fragment>{user && user.user.Useravatar && <img src={`/`+user.user.Useravatar.replace(/uploads\\/g, '')} alt="Avatar" className="topbaravatar img-fluid rounded-circle dropdown-toggle " id="avatarDropdown" data-bs-toggle="dropdown" aria-expanded="false"/>}
      
      <ul className="dropdown-menu" aria-labelledby="avatarDropdown">
        <li><a className="dropdown-item" href="/slp/profile">Profile</a></li>
        <li><hr className="dropdown-divider"/></li>
        <li><a className="dropdown-item" onClick={logout}>Logout</a></li>
      </ul></Fragment><h6 style={{float:'right',fontSize:'12px',fontWeight:'bold',paddingTop:'15px',paddingLeft: '5px'}}>{usercurrName}</h6></div>
      
      
    
    </nav>
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logout,getCurrentProfile})(Navbar);
