import React, { useState,useEffect   } from 'react';
import { connect,useDispatch,useSelector } from 'react-redux';
import { slpaddStudent,addParent,fetchParent,fetchSlp } from '../../actions/students';
import { getLessons} from '../../actions/slp';

const AddStudent = ({ onStudentAdded }) => {
  
  const dispatch = useDispatch();
  

  useEffect(() => {
    dispatch(fetchSlp());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getLessons());
  }, [dispatch]);


  const { slps } = useSelector((state) => state.student);
  const { lessons} = useSelector((state) => state.slp);
    const [userData, setUserData] = useState({
        parentName: '',
        parentEmail: '',
        parentPhone: '',
        stuName:'',
        stuGrade:'',
        stuCurriculum: [],
        stuSlp: []
      });
      const [students, setStudents] = useState([{ stuName: '', stuGrade: '',stuCurriculum:[],stuSlp:[] }]);
      const [phone, setPhone] = useState('');
      const [loading, setLoading,error] = useState(false);
      const { parent } = useSelector(state => state.parent);
      const handleUserChange = e => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
      };
    
      
      
      const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const parentName = parent?parent.parentName:userData.parentName;
        const parentEmail = parent?parent.parentEmail:userData.parentEmail;
        const parentPhone = parent?parent.parentPhone:userData.parentPhone;

        dispatch(addParent({ parentName: parentName, parentEmail: parentEmail,parentPhone:parentPhone }))
        .then((parentResponse) => {
          const parentId = parentResponse._id;
            dispatch(slpaddStudent({ stuName:userData.stuName,stuGrade:userData.stuGrade,stuCurriculum:userData.stuCurriculum,stuSlp:userData.stuSlp, parentId:parentId }));
            setLoading(false);
            onStudentAdded();
            setUserData({ parentName: '',parentEmail: '',parentPhone: '', stuName:'',stuGrade:'',stuCurriculum: [],stuSlp: []});
            
          alert('Parent and students added successfully!');
        })
        .catch((error) => {
          console.error('Error adding parent and students:', error);
          alert('An error occurred while adding parent and students');
        });
      };
    
    
      const handleCheckboxChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
          if (name === 'stuSlp') {
            const updatedstuSlp = checked
              ? [...userData.stuSlp, value]
              : userData.stuSlp.filter(course => course !== value);
            setUserData({ ...userData, stuSlp: updatedstuSlp });
          } else if (name === 'stuCurriculum') {
            const updatedstuCurriculum = checked
              ? [...userData.stuCurriculum, value]
              : userData.stuCurriculum.filter(teacher => teacher !== value);
            setUserData({ ...userData, stuCurriculum: updatedstuCurriculum });
          }
        } else {
          setUserData({ ...userData, [name]: value });
        }
      };

  
      const [selectAll, setSelectAll] = useState(false);
      const [selectAllCrriculum, setselectAllCrriculum] = useState(false);
      
      const toggleSelectAll = () => {
        // Toggle the state of selectAll
        setSelectAll(prevState => !prevState);
        
        // Get all checkboxes with the name 'stuSlp'
        const checkboxes = document.getElementsByName('stuSlp');
        
        // Iterate over each checkbox and update its checked status
        checkboxes.forEach((checkbox) => {
            checkbox.checked = !selectAll;
        });
    
        // Update the state value
        setSelectAll(!selectAll);
    
        // Update the userData state based on the new checked status of checkboxes
        const updatedstuSlp = Array.from(checkboxes)
        .filter(checkbox => checkbox.checked)
            .map(checkbox => checkbox.value);
    console.log(updatedstuSlp);
        // Update the userData state with the new stuSlp data
        setUserData({ ...userData, stuSlp: updatedstuSlp });
    };
    
    

    const toggleCrriculumSelectAll = () => {
      // Toggle the state of selectAllCrriculum
      setselectAllCrriculum(prevState => !prevState);
      
      // Get all checkboxes with the name 'stuCurriculum'
      const checkboxes = document.getElementsByName('stuCurriculum');
      
      // Iterate over each checkbox and update its checked status
      checkboxes.forEach((checkbox) => {
          checkbox.checked = !selectAllCrriculum;
      });
  
      // Update the state value
      setselectAllCrriculum(!selectAllCrriculum);
  
      // Update the userData state based on the new checked status of checkboxes
      const updatedstuCurriculum = Array.from(checkboxes)
          .filter(checkbox => checkbox.checked)
          .map(checkbox => checkbox.value);
          console.log(updatedstuCurriculum);
      // Update the userData state with the new curriculum data
      setUserData({ ...userData, stuCurriculum: updatedstuCurriculum });
  };
  
    
    
    
    
      
    


      const handleSearch = async () => {
        dispatch(fetchParent(phone))
          .then(response => {
            return response.json();
          })
          .then(data => {
            console.log('Data:', data); // Log the parsed JSON data
          })
          .catch(error => {
            console.error('Error:', error); // Log any errors
          });
      };


  return (
    
    <div className="container userregslp card">
     
      
      
      



    <form onSubmit={handleSubmit} className="mt-3">
      
      <h5 className="mt-4">Parent Information</h5>
      <div className="form-group">
        <input
          type="text"
          name="parentName"
          placeholder="Full Name"
          value={parent?parent.parentName:userData.parentName}
          onChange={handleUserChange}
          className="form-control"
          required
        />
      </div>
      <div className="form-group">
        <input
          type="email"
          name="parentEmail"
          placeholder="Email"
          value={parent?parent.parentEmail:userData.parentEmail}
          onChange={handleUserChange}
          className="form-control"
          required
        />
      </div>
      <div className="form-group">
        <input
          type="text"
          name="parentPhone"
          placeholder="Phone number"
          value={parent?parent.parentPhone:userData.parentPhone}
          onChange={handleUserChange}
          className="form-control"
          required
        />
      </div>
      <h5 className="mt-5">Student Information</h5>
      <div className="form-group">
        <input
          type="text"
          name="stuName"
          placeholder="Nickname"
          value={userData.stuName}
          onChange={handleUserChange}
          className="form-control"
          required
        />
      </div>
      <div className="form-group">
        <input
          type="text"
          name="stuGrade"
          placeholder="Grade"
          value={userData.stuGrade}
          onChange={handleUserChange}
          className="form-control"
          required
        />
      </div>
      <div className='row'>
        <div className='col-lg-3'><h6 style={{fontWeight:'bold'}}>SLP</h6></div>
        <div className='col'><h6 style={{fontWeight:'bold'}}>Select All <input
          type="checkbox"
          checked={selectAll}
          onChange={toggleSelectAll}
        />
       
      </h6></div>
        </div>
        <div className="form-group curriculum">
        {slps.length > 0 ? (
  slps.map((slp, index) => (
    <div className="card m-r-10" key={index}>
      <div className="card-body">
        <div className="form-check">
          <label className="form-check-label">{slp.firstname} {slp.lastname}</label>
          <input
            type="checkbox"
            className="form-check-input"
            name="stuSlp"
            value={slp._id}
            onChange={handleCheckboxChange}
          />
        </div>
      </div>
    </div>
  ))
) : (
  <div>No teachers available</div>
)}
            {/* Add more curriculum checkboxes as needed */}
          </div>
          <div style={{clear:'both'}}>&nbsp;</div>
          <div className='row'>
        <div className='col-lg-3'><h6 style={{fontWeight:'bold'}}>Curriculum</h6></div>
        <div className='col'><h6 style={{fontWeight:'bold'}}>Select All <input
          type="checkbox"
          checked={selectAllCrriculum}
          onChange={toggleCrriculumSelectAll}
        /></h6></div>
        </div>

        <div className="form-group curriculum lesson">
        {lessons.length > 0 ? (
          lessons.map((lesson, index) => (
           <div className="card m-r-10">
           
             <div className="card-body">
               <div className="form-check">
               <label className="form-check-label" style={{fontSize:'13px'}}>{lesson.lessonname} ({lesson.subcategory})</label>
                 <input
                   type="checkbox"
                   className="form-check-input"
                   name="stuCurriculum"
                   value={lesson._id}
                   onChange={handleCheckboxChange}
                 />
                 
               </div>
             </div>
           </div>
          ))
        ) : (
          <div>No Lessons available</div>
        )}
        </div>

          <div style={{clear:'both'}}>&nbsp;</div>
      <div className='row m-b-10'>
          <div className='col' style={{width:'160px'}}><button type="submit" className="btn btn-light" style={{width:'170px',margin:'3px'}}>Add another child</button></div>
          <div className='col' style={{width:'160px'}}><button type="submit" className="btn btn-secondary"  style={{width:'170px',margin:'3px'}}>Cancel</button></div>
          <div className='col' style={{width:'160px'}}><button type="submit" className="btn btn-success" style={{width:'170px',margin:'3px'}}>Save</button></div>
      </div>
      
    </form>
    {loading && (
              <div className="overlay d-flex justify-content-center align-items-center">
              <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
              </div>
          </div>
            )}
  </div>
  
  
  );
};



const mapStateToProps = (state) => ({
  auth: state.auth
 
});

export default connect(mapStateToProps)(
  AddStudent
);
