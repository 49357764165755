import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getCurrentProfile, deleteAccount } from '../../actions/profile';
import ParentLeftNav from '../../components/layout/ParentLeftNav';
import Navbar from '../../components/layout/Navbar';
import { getParent } from '../../actions/parent';
import { getStudent, fetchStuReport } from '../../actions/students';
import man1 from '../../images/man1.png';
import man2 from '../../images/man2.png';
import man3 from '../../images/fem3.png';
import man4 from '../../images/user-male.png';
import man5 from '../../images/user-female.png';
import cardback from '../../images/cardback.png';
import tback from '../../images/tback.png';
import gl from '../../images/glesson.png';
import stickers from '../../images/ws.png';
import star from '../../images/video-lesson1.png';
import coursecomp from '../../images/coursecomp.png';
import lesson1 from '../../images/IMG.png';
import lesson2 from '../../images/IMG1.png';
import lesson3 from '../../images/IMG2.png';
import lesson4 from '../../images/IMG3.png';
import iconlesson from '../../images/Icon-Lessons.png';
import sticker01 from '../../images/sticker-01.png';
import sticker02 from '../../images/sticker-02.png';
import sticker03 from '../../images/sticker-03.png';
import sticker04 from '../../images/sticker-04.png';
import sticker05 from '../../images/sticker-05.png';
import sticker06 from '../../images/sticker-06.png';

const avatarImages = [man1, man2, man3, man4, man5];

const ParentDash = ({
  getCurrentProfile,
  deleteAccount,
  isOpen,
  auth: { user },
  profile: { profile }
}) => {
  useEffect(() => {
    getCurrentProfile();
  }, [getCurrentProfile]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getParent(user.user.email));
  }, [dispatch, user.user.email]);

  const { student, loading, error, filter, page, parent, slp, lesson } = useSelector((state) => state.parent);

  const studid = student[0] ? student[0]._id : '';
  const { students, stuReport } = useSelector((state) => state.student);
  useEffect(() => {
    if (studid) {
      dispatch(getStudent(studid));
      dispatch(fetchStuReport(studid));
    }
  }, [dispatch, studid]);

  const lessonStatus = "completed"; // Status you want to count
  const lessonStatusCount = stuReport.filter(report => report.lessonstatus === lessonStatus).length;
  

  const totalAchievementCount = stuReport.reduce((total, report) => total + report.lachievement.length, 0);
  const transformedReport = stuReport.reduce((acc, report) => {
    const lessonId = report.lessonid;

    // If the lessonId is not yet in the accumulator, create a new entry
    if (!acc[lessonId]) {
      acc[lessonId] = {
        wordsspoken: [],
        count: 0
      };
    }

    // Push words spoken in the current report to the corresponding lesson
    acc[lessonId].wordsspoken.push(...report.wordsspoken);

    // Increment the count of reports for the lesson
    acc[lessonId].count++;

    return acc;
  }, {});
  
  
  return (
    <section>
      <div className="App">
        <div className="container-fluid">
          <div className="row">
            {/* Left Navigation Sidebar */}
            <div className="col-md-3">
              <ParentLeftNav />
            </div>

            {/* Main Content */}
            <div className={`col-md-12 ${isOpen ? 'content' : 'togcontent'}`}>
              <Navbar />
              <div className={`p-20 ${isOpen ? 'innercontent' : 'toginnercontent'}`}>
                {student[0] && students && students.student && (
                  <div className='row '>
                    <div className='col-lg-9 stuprofile'>
                      <div className="col">
                        <div className="flip-card">
                          <div>
                            <div className="flip-card-front" style={{ borderRadius: '10px' }}>
                              <div className="card-header" style={{ backgroundImage: `url(${cardback})`, height: '150px', width: '100%', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
                              

                                {student && student.avatar ? (
  <img 
    src={`/${student.avatar.replace(/uploads\\/g, '')}`} 
    alt="Avatar" 
    className="avatar"
  />
) : (
  <img 
    src={man2} 
    alt="Student Profile" 
    className="avatar" 
  />
)}
                              </div>
                              <div className="card-body" style={{ textAlign: 'center' }}>
                                <h5 style={{ fontWeight: 'bold', fontsize: '16px', marginTop: '20px' }}>Student ID: {students.student._id}</h5>
                                <div>Grade:{students.student.stuGrade}</div>
                              </div>
                              <div className='row p-20' style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '14px' }}>
                                <div className='col btnbdr bx-shadow m-r-10' style={{ width: '200px' }}><img src={gl} /><br />Lessons<br /><div style={{ width: '160px', background: '#EDEDED', padding: '3px 10px', margin: '0 auto', borderRadius: '7px' }}>{students.student.stuLessonId.length}</div></div>
                                <div className='col btnbdr bx-shadow m-r-10' style={{ width: '200px' }}><img src={stickers} /><br />Stickers<br /><div style={{ width: '160px', background: '#EDEDED', padding: '3px 10px', margin: '0 auto', borderRadius: '7px' }}>{totalAchievementCount}</div></div>
                                <div className='col btnbdr bx-shadow m-r-10' style={{ width: '200px' }}><img src={coursecomp} /><br />Courses Completed<br /><div style={{ width: '160px', background: '#EDEDED', padding: '3px 10px', margin: '0 auto', borderRadius: '7px' }}>{lessonStatusCount}</div></div>
                                <div className='col btnbdr bx-shadow' style={{ width: '200px' }}><img src={coursecomp} /><br />Courses Incompleted<br /><div style={{ width: '160px', background: '#EDEDED', padding: '3px 10px', margin: '0 auto', borderRadius: '7px' }}>{(lessonStatusCount == 0)?1:lessonStatusCount}</div></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='row lessonspl' style={{ marginTop: '20px' }}>
                        <div><h5 style={{ fontWeight: 'bold' }}>Lessons</h5></div>
                        <div className='col-lg-12'>
                          <div className="card-container">
                            {students.lessons ? (
                              students.lessons.map((lesson, index) => (
                                <div className="card m-r-10" key={index}>
                                  <img src={lesson.subcatimage} alt="Header" className="card-header" />
                                  <div className="card-body">
                                    <h5 className="card-title">{lesson.lessonname}</h5>
                                    <h6 style={{ fontWeight: '600' }}>{lesson.subcategory}</h6>
                                    {/* Calculate the number of words left */}
                                    {transformedReport[lesson._id] ? (
  <div>
    <h6 className="card-text">
      <img style={{ width: '15px' }} src={iconlesson} />
      &nbsp;
      {lesson.words.split(",").length}/
      {transformedReport[lesson._id].wordsspoken ? 
        lesson.words.split(",").length - transformedReport[lesson._id].wordsspoken.length :
        lesson.words.split(",").length
      } words left
    </h6>
    {/* Calculate the percentage of words completed */}
    {(() => {
      const totalWords = lesson.words.split(",").length;
      const wordsSpoken = transformedReport[lesson._id].wordsspoken ? transformedReport[lesson._id].wordsspoken.length : 0;
      const percentComplete = (wordsSpoken / totalWords) * 100;
      return (
        <div className="progress">
          <div className="progress-sm progress-bar progress-bar-success" role="progressbar" style={{ width: `${percentComplete}%` }} aria-valuenow={percentComplete} aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      );
    })()}
  </div>
) : (
  <div>
    <h6 className="card-text">
      <img style={{ width: '15px' }} src={iconlesson} />
      &nbsp;
      {lesson.words.split(",").length} words left
    </h6>
    {/* Render the progress bar with 0% width */}
    <div className="progress">
      <div className="progress-sm progress-bar progress-bar-success" role="progressbar" style={{ width: '0%' }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
  </div>
)}

                         </div>
                                </div>
                              ))
                            ) : (
                              <p>Loading...</p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div><h5 style={{ fontWeight: 'bold' }}>Stickers</h5></div>
                        {totalAchievementCount > 0 ? (
  // Render the achievements if there are any
  Array.from({ length: totalAchievementCount }, (_, index) => (
    <div key={index} className='col-md-10 card m-r-10' style={{ padding: '25px', textAlign: 'center', fontWeight: 'bold' }}>
      <img style={{ width: '70px', margin: '0 auto' }} src={sticker01} /><br />
      Sticker Name
    </div>
  ))
) : (
  // Show a message if there are no achievements
  <div>No Stickers found</div>
)}
                      </div>
                    </div>
                    <div className='col-lg-3'>
                      {students.slps ? (
                        students.slps.map((slp, index) => (
                          <div className="col m-b-10" key={index}>
                            <div className="flip-card stuprofile">
                              <div className="flip-card-front" style={{ borderRadius: '10px' }}>
                                <div className="card-header" style={{ backgroundImage: `url(${tback})`, height: '128px', width: '100%', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
                                  {slp && slp.avatar && <img src={`/` + slp.avatar.replace(/uploads\\/g, '')} alt="Avatar" className="avatar" style={{ background: "#f3de94", border: 'none' }} />}
                                </div>
                                <h5 style={{ fontWeight: 'bold', fontSize: '16px', marginTop: '20px' }}>{slp.firstname + " " + slp.lastname}</h5>
                                <div className="card-body" style={{ textAlign: 'center', color: '#838383' }}>
                                  <div>{slp.title}</div>
                                  <div>{slp.email}</div>
                                  <div>{slp.phone}</div>
                                  <div class="border m-t-30 rounded">
                                    <img className='p-t-10' src={star} style={{ width: '20px' }} />
                                    <div><h7 style={{ fontWeight: 'bold' }}>Rattings</h7></div>
                                    <div className='rounded m-15' style={{ background: '#EDEDED', padding: '3px 40px' }}>{slp.ratting ? slp.ratting : 'No Rattings Found'}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p>Loading...</p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ParentDash.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  isOpen: state.auth.isOpen !== undefined ? state.auth.isOpen : true, 
});

export default connect(mapStateToProps, { getCurrentProfile, deleteAccount })(ParentDash);
