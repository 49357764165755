import {
    GET_USER_SUCCESS,
    GET_USER_FAILURE,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,
    GET_LESSON_REQUEST,
    GET_LESSONS_SUCCESS,
    GET_LESSONS_FAILURE,
    IMPORT_LESSONS_REQUEST,
    IMPORT_LESSONS_SUCCESS,
    IMPORT_LESSONS_FAILURE,
    GET_LESSONS_COUNT_SUCCESS,
    GET_LESSONS_COUNT_FAILURE,
    GET_ACHIEVEMENT_COUNT_SUCCESS,
    GET_ACHIEVEMENT_COUNT_FAILURE,
    SET_FILTER,
    SET_PAGE,
  } from '../actions/types';
  
  const initialState = {
    slp: {},
    error: null,
    user:null,
    loading: false,
    lessons: [],
    filter: 'active', 
    page: 1,
    totalPages: 1,
    lessonCount:null,
    achievementCount:[]
  };
  
  const slpReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_USER_SUCCESS:
      case UPDATE_USER_SUCCESS:
        return {
          ...state,
          slp: action.payload,
          error: null,
        };
        case GET_LESSON_REQUEST:
          return {
            ...state,
            loading: true,
            error: null
          };
      case GET_LESSONS_SUCCESS:
          return {
            ...state,
            lessons: action.payload.lessons,
            totalPages: action.payload.totalPages,
            error: null,
            loading: false,
          };
      case GET_LESSONS_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload
        };
        case GET_LESSONS_COUNT_SUCCESS:
          return {
            ...state,
            lessonCount: action.payload,
            loading: false,
            error: null
          };
        case IMPORT_LESSONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case IMPORT_LESSONS_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case IMPORT_LESSONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
      case GET_ACHIEVEMENT_COUNT_SUCCESS:
          return {
            ...state,
            achievementCount: action.payload,
            error: null,
            loading: false,
          };
      case SET_FILTER:
        return { ...state, filter: action.payload, page: 1 };
      case SET_PAGE:
        return { ...state, page: action.payload };
      case GET_USER_FAILURE:
      case UPDATE_USER_FAILURE:
        return {
          ...state,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default slpReducer;
  