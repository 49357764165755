import React, { useEffect,useState  } from 'react';

import { useSelector, useDispatch,connect } from 'react-redux';
import PropTypes from 'prop-types';
import SlpNavbar from '../../components/layout/SlpNavbar';
import Navbar from '../../components/layout/Navbar';
import Chart from '../dashboard/VerticalBarChart';
import TeacherData from '../layout/DataGridWithSearch';
import DashSlpStu from '../dashboard/DashSlpStu';
import DashLesson from '../dashboard/DashLesson';
import AddStuForm from '../dashboard/AddStudent';
import totalslps from '../../images/tslps.png';
import totalstu from '../../images/tstu.png';


import analytics from '../../images/Icon-Analytics.png';

import itc from '../../images/Icon-total-courses.png';
import totalstk from '../../images/totalstk.png';
import graduates from '../../images/Icon-Graduates.png';
import satisfaction from '../../images/Icon-satisfaction-rating.png';
import lesson1 from '../../images/IMG.png';
import lesson2 from '../../images/IMG1.png';
import lesson3 from '../../images/IMG2.png';
import lesson4 from '../../images/IMG3.png';
import iconlesson from '../../images/Icon-Lessons.png';
import { fetchStudentCount,fetchStudentActiveCount } from '../../actions/students';
import {getLessonCount,getAchievementCount} from '../../actions/slp';
const SlpDashboard = ({
  isOpen,
  getCurrentProfile,
  deleteAccount,
  auth: { user }
})  => {
 
  const [refreshGrid, setRefreshGrid] = useState(false);
  
  const handleStudentAdded = () => {
    setRefreshGrid(!refreshGrid);
  };
  
  const studentCount = useSelector(state => state.student.studentCount);
  const studentActiveCount = useSelector(state => state.student.studentActiveCount);
  
  const { lessonCount, achievementCount} = useSelector((state) => state.slp);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchStudentCount());
    dispatch(fetchStudentActiveCount());
    dispatch(getLessonCount());
    dispatch(getAchievementCount(user.user.id));
  }, [dispatch]);

  
  return (
    <section>
      <div className="App">
      <div className="container-fluid">
        <div className="row">
          {/* Left Navigation Sidebar */}
          <div className="col-md-3">
            <SlpNavbar/>
          </div>

          {/* Main Content */}
          <div className={`col-md-12 ${isOpen ? 'content' : 'togcontent'}`}>
            <Navbar/>
            <div className={`p-20 ${isOpen ? 'innercontent' : 'toginnercontent'}`}>
              <div><h4>Dashboard</h4></div>
              <div className="row">
    <div className="col-12 col-lg-5">
      <div className="row dashboard">
        <div className="col-6 col-md-6">
          <div className="card card-fill text-white" style={{ backgroundColor: '#5058F0' }}>
            <div className="card-body dashcard">
              <div className="dcardvalue">{studentCount}</div>
              <div className="mb-0 float-left" style={{ fontWeight: 'bold', fontSize: '14px' }}>Total Students</div>
              <div><img src={totalstu} alt="Total Students" /></div>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-6">
          <div className="card card-fill text-white" style={{ backgroundColor: '#FF8423' }}>
            <div className="card-body dashcard">
              <div className="dcardvalue">{studentActiveCount}</div>
              <div className="mb-0 float-left" style={{ fontWeight: 'bold', fontSize: '14px' }}>Active Attendance</div>
              <div><img src={totalslps} alt="Active Attendance" /></div>
            </div>
          </div>
        </div>
      </div>
      <div className="row dashboard">
        <div className="col-6 col-md-6">
          <div className="card card-fill text-white" style={{ backgroundColor: '#00AFF0' }}>
            <div className="card-body dashcard">
              <div className="dcardvalue">{lessonCount}</div>
              <div className="mb-0 float-left" style={{ fontWeight: 'bold', fontSize: '14px' }}>Total Courses</div>
              <div><img src={itc} alt="Total Courses" /></div>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-6">
          <div className="card card-fill text-white" style={{ backgroundColor: '#FFC107' }}>
            <div className="card-body dashcard">
              <div className="dcardvalue">{achievementCount}</div>
              <div className="mb-0 float-left" style={{ fontWeight: 'bold', fontSize: '14px' }}>Total Sticker Awarded</div>
              <div><img src={totalstk} alt="Total Sticker Awarded" /></div>
            </div>
          </div>
        </div>
      </div>
      <div className="row dashboard">
        <div className="col-6 col-md-6">
          <div className="card card-fill text-white" style={{ backgroundColor: '#FF5274' }}>
            <div className="card-body dashcard">
              <div className="dcardvalue">0</div>
              <div className="mb-0 float-left" style={{ fontWeight: 'bold', fontSize: '14px' }}>Graduates<img src={analytics} alt="Graduates" /></div>
              <div><img src={graduates} alt="Graduates" /></div>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-6">
          <div className="card card-fill text-white" style={{ backgroundColor: '#660090' }}>
            <div className="card-body dashcard">
              <div className="dcardvalue">3 Star</div>
              <div className="mb-0 float-left" style={{ fontWeight: 'bold', fontSize: '14px' }}>Satisfaction Rating</div>
              <div><img src={satisfaction} alt="Satisfaction Rating" /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-12 col-lg-7">
      <div className="card text-white">
        <div className="card-body">
          <p className="mb-0"><Chart /></p>
        </div>
      </div>
    </div>
  </div>







              <div className='row p-t-15'>
              <div className='col-lg-12'>
                
                      
                
  <div className="row">
    <div className='col'>
    
  <DashSlpStu/>
  </div>
  </div>
  <div className="row p-t-15 p-b-10">
    <div className='col'>
    
  <DashLesson/>
  </div>
  </div>
      
<div className='row p-t-15'>

  <div className='col'>
    <div className='row'><div className='col'><h4 style={{fontWeight:'bold'}}>Students Enrollment</h4></div></div>
  <AddStuForm onStudentAdded={handleStudentAdded}/>
  </div>
    
  <div className='col'>
  <div className='row'><div className='col'><div style={{float:'left'}}><h4 style={{fontWeight:'bold'}}>Student Status <span style={{fontSize:'12px',fontStyle:'italic'}}><a href='/slp/students'>View All</a></span></h4></div></div></div>
    <TeacherData key={refreshGrid} onStudentAdded={handleStudentAdded}/>
    </div>
</div>


                  </div>  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
  );
};

SlpDashboard.propTypes = {
  auth: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  isOpen: state.auth.isOpen !== undefined ? state.auth.isOpen : true,
});

export default connect(mapStateToProps)(
  SlpDashboard
);
