import {
    ADD_NEW_SLP,
    ADD_NEW_SLP_FAILURE,
    GET_TEACHERS_REQUEST,
    GET_TEACHERS_SUCCESS,
    GET_TEACHER,
    GET_TEACHERS_GRID,
    GET_TEACHERS_FAILURE,
    GET_SLP_COUNT,
    GET_STUDENT_COUNT,
    GET_ACTIVE_SLP_COUNT,
    GET_ACTIVE_STUDENT_COUNT,
    SET_FILTER,
    SET_PAGE,
  } from '../actions/types';
  
  const initialState = {
    slps: [],
    error: null,
    loading: false,
    filter: 'active', 
    page: 1,
    totalPages: 1,
    slp:{},
    slpCount:0,
    stuCount:0,
    actSlpCount:0,
    actstucount:0
  };
  
  const adminReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADD_NEW_SLP:
        return {
          ...state,
          slps: action.payload,
          error: null,
        };
      case ADD_NEW_SLP_FAILURE:
        return {
          ...state,
          error: action.payload,
        };
      case GET_TEACHERS_REQUEST:
        return {
            ...state,
            loading: true,
            error: null
        };
      case GET_TEACHERS_SUCCESS:
        return {
            ...state,
            loading: false,
            slps: action.payload.slps,
            totalPages: action.payload.totalPages
        };
      case GET_TEACHER:
          return{
            ...state,
            loading: false,
            slp: action.payload,
          };
      case GET_TEACHERS_GRID:
          return{
            ...state,
          loading: false,
          slps: action.payload,
          };
          case GET_SLP_COUNT:
            return {
              ...state,
              slpCount: action.payload,
              loading: false,
              error: null
            };
            case GET_STUDENT_COUNT:
            return {
              ...state,
              stuCount: action.payload,
              loading: false,
              error: null
            };
            case GET_ACTIVE_SLP_COUNT:
            return {
              ...state,
              actSlpCount: action.payload,
              loading: false,
              error: null
            };
            case GET_ACTIVE_STUDENT_COUNT:
            return {
              ...state,
              actstucount: action.payload,
              loading: false,
              error: null
            };
          case SET_FILTER:
            return { ...state, filter: action.payload, page: 1 };
          case SET_PAGE:
            return { ...state, page: action.payload };
      default:
        return state;
    }
  };
  
  export default adminReducer;
  