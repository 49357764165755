import api from '../utils/api';
import {
    ADD_NEW_SLP,
    ADD_NEW_SLP_FAILURE,
    GET_TEACHERS_REQUEST,
    GET_TEACHERS_SUCCESS,
    GET_TEACHERS_FAILURE,
    GET_TEACHERS_GRID,
    GET_TEACHER,
    SET_FILTER,
    GET_SLP_COUNT,
    GET_SLP_COUNT_FAILURE,
    GET_STUDENT_COUNT,
    GET_STUDENT_COUNT_FAILURE,
    GET_ACTIVE_SLP_COUNT,
    GET_ACTIVE_SLP_COUNT_FAILURE,
    GET_ACTIVE_STUDENT_COUNT,
    GET_ACTIVE_STUDENT_COUNT_FAILURE,
    SET_PAGE,
} from './types';

  
  export const addSlp = (userData) => {
    return (dispatch) => {console.log("called");
        api.put('/admin/addslp', userData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => {
          dispatch({ type: ADD_NEW_SLP, payload: res.data });
        })
        .catch(err => {
          dispatch({ type: ADD_NEW_SLP_FAILURE, payload: err.message });
        });
    };
  };


  export const getTeachers = (filter, page) => {
    return async (dispatch) => {
      dispatch({ type: GET_TEACHERS_REQUEST });
  
      try {
        const response = await api.get(`/admin/cardslps?filter=${filter}&page=${page}`);
        const { slps, totalPages } = response.data; 
       
        dispatch({ type: GET_TEACHERS_SUCCESS, payload: { slps, totalPages } });
      } catch (error) {
        dispatch({ type: GET_TEACHERS_FAILURE, payload: error.message });
      }
    };
  };



  export const getSlp = (userId) => {
    return (dispatch) => {
        api.get(`/admin/getteacher/${userId}`)
        .then(res => {console.log(res.data);
          dispatch({ type: GET_TEACHER, payload: res.data });
        })
        .catch(err => {
          dispatch({ type: GET_TEACHERS_FAILURE, payload: err.message });
        });
    };
  };
  
  export const updateSlp = (userId, userData) => {
    return (dispatch) => {
        api.put(`/admin/teachers/${userId}`, userData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => {
          dispatch({ type: GET_TEACHER, payload: res.data });
        })
        .catch(err => {
          dispatch({ type: GET_TEACHERS_FAILURE, payload: err.message });
        });
    };
  };


  export const getGridTeachers = () => async (dispatch) => {
    try {
     
      const url = '/admin/getteachersgrid';
      const res = await api.get(url);
     
      dispatch({ type: GET_TEACHERS_GRID, payload: res.data});
        
      } catch (error) {
        dispatch({ type: GET_TEACHERS_FAILURE, payload: error.message });
      }
  };

  export const deleteTeacherById = (id) => {
    return async (dispatch) => {
      try {
        await api.delete(`/students/${id}`);
        dispatch({ type: 'DELETE_STUDENT_SUCCESS', payload: id });
      } catch (error) {
        console.error('Error deleting student:', error.message);
        // Handle error if needed
      }
    };
  };

  export const setTracherStatus = (id, status) => {
    return async (dispatch) => {
      try {
        const response = await api.put(`/students/students/${id}/status`, { status });
        if (response.data.success) {
          dispatch({ type: SET_STUDENT_STATUS, payload: { id, status } });
        }
      } catch (error) {
        console.error('Error setting student status:', error);
      }
    };
  };


  export const getSlpCount = () => {
    return async (dispatch) => {
      try {
        const response = await api.get('/admin/slpcount');
        dispatch({ type: GET_SLP_COUNT, payload: response.data.count });
      } catch (error) {
        console.error('Error fetching student count:', error.message);
        dispatch({ type: GET_SLP_COUNT_FAILURE });
      }
    };
  };

  export const getStuCount = () => {
    return async (dispatch) => {
      try {
        const response = await api.get('/admin/stucount');
        dispatch({ type: GET_STUDENT_COUNT, payload: response.data.count });
      } catch (error) {
        console.error('Error fetching student count:', error.message);
        dispatch({ type: GET_STUDENT_COUNT_FAILURE });
      }
    };
  };

  export const getActSlp = () => {
    return async (dispatch) => {
      try {
        const response = await api.get('/admin/actslp');
        
        dispatch({ type: GET_ACTIVE_SLP_COUNT, payload: response.data.slpCount });
      } catch (error) {
        console.error('Error fetching student count:', error.message);
        dispatch({ type: GET_ACTIVE_SLP_COUNT_FAILURE });
      }
    };
  };


  export const getActStuCount = () => {
    return async (dispatch) => {
      try {
        const response = await api.get('/admin/stuactcount');
        dispatch({ type: GET_ACTIVE_STUDENT_COUNT, payload: response.data.count });
      } catch (error) {
        console.error('Error fetching student count:', error.message);
        dispatch({ type: GET_ACTIVE_STUDENT_COUNT_FAILURE });
      }
    };
  };


  export const setFilter = (filter) => ({
    type: SET_FILTER,
    payload: filter,
  });
  
  export const setPage = (page) => ({
    type: SET_PAGE,
    payload: page,
  });