import React, { useState, useRef,useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect,useSelector,useDispatch  } from 'react-redux';

import { getCurrentProfile, deleteAccount } from '../../actions/profile';
import {submitLesson,importLessons} from '../../actions/slp';
import SlpNavbar from '../../components/layout/SlpNavbar';
import Navbar from '../../components/layout/Navbar';

import slpal from '../../images/slpal.png';
import upl from '../../images/uploadlessons.png';
import lesson2 from '../../images/IMG1.png';
import lesson3 from '../../images/IMG2.png';
import lesson4 from '../../images/IMG3.png';
import iconlesson from '../../images/Icon-Lessons.png';
import icream1 from '../../images/sticker-01.png';
import icream2 from '../../images/sticker-02.png';
import icream3 from '../../images/sticker-03.png';
import icream4 from '../../images/sticker-04.png';
import icream5 from '../../images/sticker-05.png';
import tick from '../../images/tick.png';


const AddLesson = ({
  getCurrentProfile,
  deleteAccount,
  auth: { user },
  isOpen,
  profile: { profile }
}) => {
  useEffect(() => {
    getCurrentProfile();
  }, [getCurrentProfile]);

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    field1: '',
    field2: '',
    field3: '',
    field4: '',
    file1: null,
    file2: null,
    file3: [] // Initialized as an empty array
  });
  const [loading, setLoading] = useState(false);
  const fileInputRefs = useRef([React.createRef(), React.createRef(), React.createRef()]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

 /* const handleFileChange = (fileNumber, files) => {
    console.log("Files selected:", files); // Check the selected files
    setFormData(prevState => ({
      ...prevState,
      file3: files // Update the file3 property with the selected files
    }));
  };*/

  const handleFileChange = (fileNumber, files) => {
    // Update the state to store the file/files for the corresponding input
    setFormData(prevState => ({
      ...prevState,
      [`file${fileNumber}`]: [
        ...(prevState[`file${fileNumber}`] || []), // Existing files
        ...Array.from(files) // Newly selected files
      ]
    }));

    // If files is not an array, convert it to an array
    const filesArray = Array.from(files);
  
    // Display previews for image, video, and audio files
    filesArray.forEach(file => {
      const reader = new FileReader();
      reader.onload = () => {
        const preview = document.createElement("div");
        if (file.type.startsWith('image/')) {
          const img = document.createElement("img");
          img.src = reader.result;
          img.className = "uploaded-image";
          preview.appendChild(img);
        } else if (file.type.startsWith('video/')) {
          const video = document.createElement("video");
          video.src = reader.result;
          video.controls = true;
          video.className = "uploaded-video";
          preview.appendChild(video);
        } else if (file.type.startsWith('audio/')) {
          const audio = document.createElement("audio");
          audio.src = reader.result;
          audio.controls = true;
          audio.className = "uploaded-audio";
          preview.appendChild(audio);
        }
        document.getElementById(`uploadOption${fileNumber}`).appendChild(preview);
      };
      reader.readAsDataURL(file);
    });
};





  useEffect(() => {
    console.log("formData:", formData); // Check the formData state
  }, [formData]);
  const handleUploadClick = (index) => {
    fileInputRefs.current[index].current.click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  console.log("Form data before submission:", formData);
    try {
      const formDataToSend = new FormData();
      // Add other form fields to FormData
      formDataToSend.append('field1', formData.field1);
      formDataToSend.append('field2', formData.field2);
      formDataToSend.append('field3', formData.field3);
      formDataToSend.append('field4', formData.field4);
      formDataToSend.append('lessonimage', formData.file1);
      formDataToSend.append('videocontent', formData.file2);
      formDataToSend.append('slp', user.user.id);
      console.log("formData.file3:", formData.file3);
      const file3 = formData.file3 ? Array.from(formData.file3) : [];
      const file1 = formData.file1 ? Array.from(formData.file1) : [];
      console.log("file3:", file3);
      // Add audio files to FormData
      file3.forEach((file, index) => {
        formDataToSend.append(`audioFile${index + 1}`, file);
      });
      file1.forEach((file, index) => {
        formDataToSend.append(`pfiles${index + 1}`, file);
      });
      const response = await submitLesson(formDataToSend);
      console.log('Form submitted successfully:', response);
      

      const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));
      (async () => {
          await wait(2000); 
          setLoading(false);
          alert("Lesson Addes successfully");
          console.log("Waited for 3 seconds!");
      })();

     
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error
    }
  };
  const handleUploadFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleUpload = () => {
    if (!file) {
      console.error('No file selected.');
      return;
    }
    dispatch(importLessons(file));
  };
  const [isChecked, setIsChecked] = useState(false);

  const updateField4 = (value) => {
    setFormData({ ...formData, field4: value });
  };

  const handleInputChange = (id) => {
    updateField4(id); // Update the textbox value with the selected ice cream id
  };
  return (
    <section>
      <div className="App">
      {loading && (
              <div className="overlay d-flex justify-content-center align-items-center">
              <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
              </div>
          </div>
            )}
        <div className="container-fluid">
          <div className="row">
            {/* Left Navigation Sidebar */}
            <div className="col-md-3">
              <SlpNavbar />
            </div>

            {/* Main Content */}
            <div className={`col-md-12 ${isOpen ? 'content' : 'togcontent'}`}>
              <Navbar />
              <div className={`p-20 ${isOpen ? 'innercontent' : 'toginnercontent'}`}>
                <div className='row'>
                  <div className='col'><h4>Create Curriculum</h4></div>
                </div>
                <div className='card'>
                  <div className='row card-body' style={{ padding: '0px' }}>
                    <div className='col-lg-2' style={{ background: 'black' }}>
                      <img style={{ width: '80px', position: 'relative', top: '50%', transform: 'translateY(-50%)', margin: '0 auto', display: 'block' }} src={slpal} />
                      <span style={{ position: 'relative', color: 'white', top: '45%', fontSize: '13px' }}>Note: Please use CSV template to ensure accurate data parsing</span>
                    </div>
                    <div className='col-lg-10'>
                      <div style={{ width: '95%', padding: '80px', border: '1px dashed', background: '#f7f6f9', textAlign: 'center', marginTop: '30px', marginLeft: '20px' }}>
                        Upload CSV file or add content below
                        <div className='row' style={{ justifyContent: 'center' }}>
                          <div className='col-lg-6'>
                            <input type="file" className="form-control" name="file" onChange={handleUploadFileChange} />
                          </div>
                          <div className='col-lg-4'>
                            <button style={{ textAlign: 'left', float: 'left' }} onClick={handleUpload} className="btn btn-secondary">UPLOAD & IMPORT</button>
                          </div>
                        </div>
                      </div>
                      <div className="container p-20">
                        <form onSubmit={handleSubmit}>
                          <div className="mb-3">
                          <input type="hidden" name="slp" value={user.user.id} />
                            <label htmlFor="field1" className="form-label" style={{ fontStyle: 'italic', fontWeight: 'bold' }}>Curriculum Name*</label>
                            <input type="text" className="form-control" id="field1" name="field1" value={formData.field1} onChange={handleChange} />
                          </div>
                          <div className="mb-3">
                            <label htmlFor="field2" className="form-label" style={{ fontStyle: 'italic', fontWeight: 'bold' }}>Curriculum Sub-category*</label>
                            <input type="text" className="form-control" id="field2" name="field2" value={formData.field2} onChange={handleChange} />
                          </div>
                          <div className="mb-3">
                            <label htmlFor="field3" className="form-label" style={{ fontStyle: 'italic', fontWeight: 'bold' }}>Words</label> <span style={{ fontSize: '12px' }}>Note: Please add comma between words</span>
                            <input type="text" className="form-control" id="field3" name="field3" value={formData.field3} onChange={handleChange} />
                          </div>
                          <div className="mb-3 row">
                            <div className="col-sm">
                              <label>Images</label>
                              <div className="upload-option" id="uploadOption1" style={{ backgroundColor: '#f7f6f9', padding: '10px', marginBottom: '10px', height: '170px' }} onClick={() => handleUploadClick(0)}>
                                <input type="file" name="lessonimage" style={{ display: 'none' }} ref={fileInputRefs.current[0]} onChange={(e) => handleFileChange(1, e.target.files)} multiple/>
                                <span style={{ fontStyle: 'italic', fontSize: '12px' }}>Upload image or select from library</span>
                              </div>
                            </div>
                            <div className="col-sm">
                              <label>Video</label>
                              <div className="upload-option" id="uploadOption2" style={{ backgroundColor: '#f7f6f9', padding: '10px', marginBottom: '10px', height: '170px' }} onClick={() => handleUploadClick(1)}>
                                <input type="file" videocontent style={{ display: 'none' }} ref={fileInputRefs.current[1]} onChange={(e) => handleFileChange(2, e.target.files)} />
                                <span style={{ fontStyle: 'italic', fontSize: '12px' }}>Upload video or select from library</span>
                              </div>
                            </div>
                            <div className="col-sm">
                              <label>Audio</label>
                              <div className="upload-option" id="uploadOption3" style={{ backgroundColor: '#f7f6f9', padding: '10px', marginBottom: '10px', height: '170px' }} onClick={() => handleUploadClick(2)}>
                                <input type="file" style={{ display: 'none' }} ref={fileInputRefs.current[2]} onChange={(e) => {handleFileChange(3, e.target.files); e.target.value = null; }}  multiple />
                                <span style={{ fontStyle: 'italic', fontSize: '12px' }}>Upload Audio or select from library</span>
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <label htmlFor="field4" className="form-label" style={{ fontStyle: 'italic', fontWeight: 'bold' }}>Achievement Badges <small style={{fontStyle:'normal',fontSize:'10px'}}>(please select any one)</small></label>
                            <input type="text" style={{display:'none'}} className="form-control" id="field4" name="field4" value={formData.field4} onChange={handleChange} />
                          <div className='row row-cols-4'>
                            {Array.from({ length: 5 }, (_, i) => {
          const iceCreamId = `icream${i + 1}`;
          const iceCreamImage = [icream1, icream2, icream3, icream4, icream5][i];
          return (
            <div className='card col-sm m-20'>
            <label key={iceCreamId} htmlFor={iceCreamId} className="image-radio-label d-flex justify-content-center align-items-center">
              <input
                type="radio"
                id={iceCreamId}
                name="icream"
                checked={formData.field4 === iceCreamId} // Check if the ice cream is currently selected
                onChange={() => handleInputChange(iceCreamId)}
                style={{display:'none'}}
              />
              <img
                src={iceCreamImage}
                alt={`Ice Cream ${i + 1}`}
                className={`image-radio-img ${formData.field4 === iceCreamId ? 'checked' : ''}`}
              />
              {formData.field4 === iceCreamId && <img src={tick} alt="Checkmark" className="green-tick" />}
            </label>
            </div>
          );
        })}
      </div>
      
                          </div>
                          <button style={{padding:'10px 80px',float:'right',marginBottom:'20px'}} type="submit" className="btn btn-primary">Submit</button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
};
AddLesson.propTypes = {
  
  isOpen: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  isOpen: state.auth.isOpen !== undefined ? state.auth.isOpen : true,
});

export default connect(mapStateToProps, { getCurrentProfile, deleteAccount })(
    AddLesson
);
