import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams,useNavigate  } from 'react-router-dom';

import { getCurrentProfile, deleteAccount } from '../../actions/profile';
import SlpNavbar from '../layout/SlpNavbar';
import Navbar from '../../components/layout/Navbar';
import { getParent, fetchStudentById, updateStudent, updateParent } from '../../actions/parent';
import { getStudent } from '../../actions/students';
import man1 from '../../images/man1.png';
import man2 from '../../images/man2.png';
import man3 from '../../images/fem3.png';
import man4 from '../../images/user-male.png';
import pupload from '../../images/upload.png';
import 'react-datepicker/dist/react-datepicker.css';
import cardback from '../../images/tback.png';
import imgstudent from '../../images/student2.png';
import rstar from '../../images/video-lesson1.png';

const EditProfile = ({
  getCurrentProfile,
  deleteAccount,
  auth: { user },
  profile: { profile }
}) => {
  useEffect(() => {
    getCurrentProfile();
  }, [getCurrentProfile]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getParent(user.user.email));
  }, [dispatch, user.user.email]);
  const [loading, setLoading] = useState(false);
  const { stuid } = useParams();
  const { estudent, student, error, filter, page } = useSelector((state) => state.parent);
  const pstudent = useSelector((state) => state.student.students);
  console.log(pstudent.Parents);
  const [newStudentId, setNewStudentId] = useState(stuid);
  const parent = (pstudent.Parents) ? pstudent.Parents : '';

  useEffect(() => {
    dispatch(fetchStudentById(stuid));
    dispatch(getStudent(stuid));
  }, [dispatch, stuid]);

  const [studentFormData, setStudentFormData] = useState({
    studentName: estudent?.stuName || '',
    studentGrade: estudent?.stuGrade || '',
    studentDob: estudent?.dob || '',
    avatar: estudent?.avatar || '',
    _id: estudent?._id || '',
    newStudentId: stuid,
  });
  
  const [parentFormData, setParentFormData] = useState({
    parentName: parent?.parentName || '',
    parentEmail: parent?.parentEmail || '',
    parentPhone: parent?.parentPhone || '',
    pid: parent?._id || '',
    avatar: parent?.avatar || '',
  });

  useEffect(() => {
    if (estudent) {
      setStudentFormData({
        studentName: estudent.stuName,
        studentGrade: estudent.stuGrade,
        studentDob: estudent.dob,
        avatar: estudent.avatar,
        _id: estudent?._id || '',
        newStudentId: newStudentId,
      });
    }
    if (parent) {
      setParentFormData({
        parentName: parent.parentName,
        parentEmail: parent.parentEmail,
        parentPhone: parent.parentPhone,
        pid: parent._id,
        avatar: parent.avatar,
      });
    }
  }, [estudent, parent]);

  const [parentProfileImage, setParentProfileImage] = useState(null);
  const [studentProfileImageUrl, setStudentProfileImageUrl] = useState('');
  
  const handleStudentInputChange = (e) => {
    setStudentFormData({
      ...studentFormData,
      [e.target.name]: e.target.value
    });
  };

  const handleParentInputChange = (e) => {
    setParentFormData({
      ...parentFormData,
      [e.target.name]: e.target.value
    });
  };

  const handleStudentImageChange = (e) => {
    setStudentProfileImageUrl(e.target.files[0]);
  };

  const handleParentImageChange = (e) => {
    setParentProfileImage(e.target.files[0]);
  };

  const handleStudentFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const studentData = {
        ...studentFormData,
        profileImage: studentProfileImageUrl,
        newStudentId: newStudentId,
      };
      
     await dispatch(updateStudent(stuid, studentData,newStudentId)); 
     console.log(`/slp/editprofile/${newStudentId}`);
     navigate(`/slp/editprofile/${newStudentId}`);
      console.log('Student information updated successfully');
      const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));
      (async () => {
          await wait(2000); 
          setLoading(false);
          alert('Student information updated successfully');
          console.log("Waited for 3 seconds!");
      })();
    } catch (error) {
      console.error('Error updating student information:', error.message);
    }
  };

  const handleParentFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const parentData = {
        ...parentFormData,
        profileImage: parentProfileImage,
      };

      dispatch(updateParent(parentData));
      const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));
      (async () => {
          await wait(2000); 
          setLoading(false);
          alert('Parent information updated successfully');
          console.log("Waited for 3 seconds!");
      })();
      console.log('Parent information updated successfully');
    } catch (error) {
      console.error('Error updating parent information:', error.message);
    }
  };
  const handleNewStudentIdChange = (e) => {
    setNewStudentId(e.target.value);
  };
  return (
    <section>
      <div className="App">
      {loading && (
              <div className="overlay d-flex justify-content-center align-items-center">
              <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
              </div>
          </div>
            )}
        <div className="container-fluid">
          <div className="row">
            {/* Left Navigation Sidebar */}
            <div className="col-md-3">
              <SlpNavbar />
            </div>

            {/* Main Content */}
            <div className="col-md-12 content">
              <Navbar />
              <div className='innercontent p-20' style={{ height: '100vh' }}>
                <div className='row'>
                  <div className='col-lg-12'>

                    <div className='row'>
                      <div className='col card m-r-15'>
                        <div className='card-body'>
                          <div>
                            <form onSubmit={handleParentFormSubmit}>
                              <div style={{ margin: '0 auto', textAlign: 'center' }}>
                                <input type="file" id="parent-image" onChange={handleParentImageChange} style={{ display: 'none' }} />
                                <img src={pupload} style={{ cursor: 'pointer', width: '20px', position: 'absolute', left: '60%', top: '20%' }} onClick={() => document.getElementById('parent-image').click()} />
                                {studentFormData && studentFormData.avatar ? (
                                  <img
                                    src={`/${studentFormData.avatar.replace(/uploads\\/g, '')}`}
                                    alt="Avatar"
                                    style={{ width: '100px', height: '100px', borderRadius: '50%' }}
                                  />
                                ) : (
                                  <img
                                    src={man1}
                                    alt="Student Profile"
                                    style={{ width: '100px', height: '100px', borderRadius: '50%' }}
                                  />
                                )}
                              </div>
                              <h6><strong>Parent Information</strong></h6>
                              <div>
                                <input type="hidden" id="pid" className='form-control m-b-5' name="pid" value={parentFormData.pid} />
                                <input type="text" id="parentName" className='form-control m-b-5' name="parentName" value={parentFormData.parentName} onChange={handleParentInputChange} />
                              </div>
                              <div>
                                <input type="email" disabled id="parentEmail" className='form-control m-b-5' name="parentEmail" value={parentFormData.parentEmail} onChange={handleParentInputChange} />
                              </div>
                              <div>
                                <input type="text" disabled id="parentPhone" className='form-control m-b-5' name="parentPhone" value={parentFormData.parentPhone} onChange={handleParentInputChange} />
                              </div>
                              <button type="submit" className='btn' style={{ padding: '10px 160px', marginTop: '30px', backgroundColor: '#41A66C', 'background-color': '#41A66C !important', color: 'white' }}>Save</button>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className='col card m-r-15'>
                        <div className='card-body'>
                          <div>
                            <form onSubmit={handleStudentFormSubmit}>
                              <div style={{ margin: '0 auto', textAlign: 'center' }}>
                                <input type="file" id="student-image" onChange={handleStudentImageChange} style={{ display: 'none' }} />
                                <img src={pupload} style={{ cursor: 'pointer', width: '20px', position: 'absolute', left: '60%', top: '20%' }} onClick={() => document.getElementById('student-image').click()} />
                                {studentFormData && studentFormData.avatar ? (
                                  <img
                                    src={`/${studentFormData.avatar.replace(/uploads\\/g, '')}`}
                                    alt="Avatar"
                                    style={{ width: '100px', height: '100px', borderRadius: '50%' }}
                                  />
                                ) : (
                                  <img
                                    src={man2}
                                    alt="Student Profile"
                                    style={{ width: '100px', height: '100px', borderRadius: '50%' }}
                                  />
                                )}
                              </div>
                              <h6><strong>Student Information</strong></h6>
                              <div>
                              <input type="text" id="newStudentId" className='form-control m-b-5' name="newStudentId" value={newStudentId} onChange={handleNewStudentIdChange} />
                                <input type="hidden"  id="_id" className='form-control m-b-5' name="_id" value={studentFormData._id} onChange={handleStudentInputChange} />
                              </div>
                              <div>
                                <input type="text" id="studentName" className='form-control m-b-5' name="studentName" value={studentFormData.studentName} onChange={handleStudentInputChange} />
                              </div>
                              <div>
                                <input type="text" id="studentGrade" className='form-control m-b-5' name="studentGrade" value={studentFormData.studentGrade} onChange={handleStudentInputChange} />
                              </div>
                              <div style={{display:'none'}}>
                                <DatePicker
                                  id="studentDob"
                                  selected={studentFormData.studentDob}
                                  onChange={(date) => setStudentFormData({ ...studentFormData, studentDob: date })}
                                  dateFormat="yyyy-MM-dd"
                                  showMonthDropdown
                                  yearDropdownItemNumber={150}
                                  showYearDropdown
                                  scrollableYearDropdown
                                  className='form-control m-b-5'
                                />
                              </div>
                              <button type="submit" className='btn' style={{ padding: '10px 160px', marginTop: '30px', backgroundColor: '#41A66C', 'background-color': '#41A66C !important', color: 'white' }}>Save</button>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="flip-card stuprofile">
                          <div className="flip-card-front" style={{ borderRadius: '10px' }}>
                            <div className="card-header" style={{ backgroundImage: `url(${cardback})`, height: '128px', width: '100%', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
                              <img src={man1} className="avatar" alt="Avatar" />
                            </div>
                            <div className="card-body" style={{ textAlign: 'center' }}>
                              <h5 style={{ fontWeight: 'bold', fontsize: '16px', marginTop: '20px' }}>{student.parentName}</h5>
                              <div style={{ fontWeight: 'bold' }}>Helen Martin</div>
                              <div>
                                Speech Language Pathologist <br />hmartin@tempotalk.com <br />800-255-1234
                              </div>
                              <div className='slpcenter' style={{ fontWeight: 'bold', fontSize: '14px', left: '10% !important' }}>
                                <div className='btnbdr float-left' style={{ width: '150px', height: '120px' }}>
                                  <div><img src={imgstudent} /><br />Ratings</div>
                                  <div className='p-t-10'><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><span style={{ fontSize: '16px' }}>5.0</span></div>
                                </div>
                                <div className='btnbdr float-left' style={{ width: '150px', height: '120px' }}>
                                  <div><img src={rstar} /><br />Need help?</div>
                                  <div className='p-t-10 ' style={{ fontSize: '10px' }}>Send Message</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

EditProfile.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile
});

export default connect(mapStateToProps, { getCurrentProfile, deleteAccount })(EditProfile);
