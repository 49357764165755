import React, { useEffect,useState  } from 'react';

import PropTypes from 'prop-types';
import { connect,useDispatch, useSelector } from 'react-redux';
import LeftNav from '../../components/layout/LeftNav';
import Navbar from '../../components/layout/Navbar';
import { getCurrentProfile, deleteAccount } from '../../actions/profile';
import {getTeachers,setFilter, setPage} from '../../actions/admin';
import student from '../../images/student2.png';
import rstar from '../../images/video-lesson1.png';
import edit from '../../images/Edit.png';

const Teachers = ({
  getCurrentProfile,
  isOpen,
  auth: { user },
  profile: { profile }
}) => {
  
  const dispatch = useDispatch();
  
  const { slps, loading, error, filter, page,totalPages } = useSelector((state) => state.admin);

   useEffect(() => {
     dispatch(getTeachers(filter, page));
   }, [dispatch, filter, page]);

   const handlePageChange = (newPage) => {
    dispatch(setPage(newPage));
  };
   const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button key={i} className={page === i ? 'active' : ''} onClick={() => handlePageChange(i)}>
          {i}
        </button>
      );
    }
    return pageNumbers;
  };
 

  return (
    <section>
      <div className="App">
      <div className="container-fluid">
        <div className="row">
          {/* Left Navigation Sidebar */}
          <div className="col-md-3">
            <LeftNav/>
          </div>

          {/* Main Content */}
          <div className={`col-md-12 ${isOpen ? 'content' : 'togcontent'}`}>
            <Navbar/>
            <div className={`slpstudent p-20 ${isOpen ? 'innercontent' : 'toginnercontent'}`}>
             <div className='row p-b-10'>
                <div className='col-lg-10 '><h4 style={{fontWeight:'bold'}}>Speech Language Pathologist</h4></div>
                <div className='col' style={{textAlign:'right'}}><a style={{padding:'5px 30px !important'}} className='btn btn-secondary btn-sm' href='/admin/addteacher'>Create SLP</a></div>
             </div>
           
             <div className='row'>
             <div className='col-lg-12'>

            
            
     
             <div className="row">
  {slps.map((slp, index) => {
    const groupIndex = Math.floor(index / 4); // Group index for every 4 cards
    //const shouldApplyClass = index % 4 !== 3;
    const shouldApplyClass = isOpen ? (index % 4 !== 3) : (index % 5 !== 4);
    return (
      <div key={index} className={`card col-lg-3 ${shouldApplyClass ? 'm-r-15' : ''}`}  style={{height: isOpen ? '450px' : '450px',width: isOpen ? '306px' : '272px'}}>
        {slp && slp.avatar && (
          <img
            src={`/${slp.avatar.replace(/uploads\\/g, '')}`}
            alt="Avatar"
            style={{ border: '2px solid', margin: '0 auto' }}
            className="rounded-circle thumb-lg m-t-15"
          />
        )}
        <div className="card-body" style={{ margin: '0 auto', textAlign: 'center' }}>
          <h5 className="card-title" style={{ color: '#202226', fontWeight: 'bold', fontSize: '16px' }}>
            {slp.firstname} {slp.lastname}
          </h5>
          <h6 style={{ fontWeight: '600', textTransform: 'capitalize', color: '#838383' }}>{slp.title}</h6>
          <h6 className="card-text" style={{ color: '#838383' }}>{slp.firstname+" "+slp.lastname}</h6>
          <h6 className="card-text" style={{ color: '#838383' }}>{slp.phone}</h6>

          <div className="slpcenter" style={{ fontWeight: 'bold', fontSize: '14px' }}>
            <div className="btnbdr float-left" style={{ width: isOpen ? '130px' : '111px', height: '120px' }}>
              <div>
                <img src={student} />
                <br />
                Students
              </div>
              <div className="p-t-10" style={{ fontSize: '16px' }}>05</div>
            </div>
            <div className="btnbdr float-left" style={{ width: isOpen ? '130px' : '111px', height: '120px' }}>
              <div>
                <img src={rstar} />
                <br />
                Ratings
              </div>
              <div className="p-t-10 srating" style={{ fontSize: '10px' }}>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <span style={{ fontSize: '16px' }}>5.0</span>
              </div>
            </div>
            <a href={`/admin/teacherprofile/${slp._id}`} className="btn btn-secondary btn-sm" style={{ background: '#008000', padding: '5px 30px 5px 30px', marginTop: '20px', width: '190px', marginRight: '30px' }} role="button" aria-pressed="true">
              Edit Profile <img style={{ width: '13px' }} src={edit} />
            </a>
          </div>
        </div>
      </div>
    );
  })}
</div>

       
     
      
   

    <div className="pagination">
        <button disabled={page === 1} onClick={() => handlePageChange(page - 1)}>Previous</button>
        {renderPageNumbers()}
        <button disabled={page === totalPages} onClick={() => handlePageChange(page + 1)}>Next</button>
      </div>

             </div>
              </div>
    
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
  );
};

Teachers.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  isOpen: state.auth.isOpen !== undefined ? state.auth.isOpen : true,
});

export default connect(mapStateToProps, { getCurrentProfile, deleteAccount })(
    Teachers
);
