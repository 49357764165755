import React, { useEffect,useState  } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { connect,useDispatch, useSelector } from 'react-redux';
import { getStudent,fetchStuReport } from '../../actions/students';
import { getCurrentProfile, deleteAccount } from '../../actions/profile';
import ParentLeftNav from '../../components/layout/ParentLeftNav';
import Navbar from '../layout/Navbar';

import cardback from '../../images/cardback.png';
import tback from '../../images/tback.png';
import man1 from '../../images/man1.png';

import gl from '../../images/glesson.png';
import stickers from '../../images/ws.png';
import star from '../../images/video-lesson1.png';
import coursecomp from '../../images/coursecomp.png';

import lesson1 from '../../images/IMG.png';
import lesson2 from '../../images/IMG1.png';
import lesson3 from '../../images/IMG2.png';
import lesson4 from '../../images/IMG3.png';
import iconlesson from '../../images/Icon-Lessons.png';
import sticker01 from '../../images/sticker-01.png';
import sticker02 from '../../images/sticker-02.png';
import sticker03 from '../../images/sticker-03.png';
import sticker04 from '../../images/sticker-04.png';
import sticker05 from '../../images/sticker-05.png';
import sticker06 from '../../images/sticker-06.png';

const StuProfile = ({
  getCurrentProfile,
  auth: { user },
  profile: { profile }
}) => {
  useEffect(() => {
    getCurrentProfile();
  }, [getCurrentProfile]);
 
  
  const { id } = useParams();
  

  const dispatch = useDispatch();

   // const student = useSelector((state) => state.student.students);
const { students,stuReport } = useSelector((state) => state.student);
    useEffect(() => {
      dispatch(getStudent(id));
      dispatch(fetchStuReport(id));
    }, [dispatch]); 


    
    const lessonStatus = "completed"; // Status you want to count
    const lessonStatusCount = stuReport.filter(report => report.lessonstatus === lessonStatus).length;
    const totalAchievementCount = stuReport.reduce((total, report) => total + report.lachievement.length, 0);
    const transformedReport = stuReport.reduce((acc, report) => {
      const lessonId = report.lessonid;
      
      // If the lessonId is not yet in the accumulator, create a new entry
      if (!acc[lessonId]) {
        acc[lessonId] = {
          wordsspoken: [],
          count: 0
        };
      }
      
      // Push words spoken in the current report to the corresponding lesson
      acc[lessonId].wordsspoken.push(...report.wordsspoken);
      
      // Increment the count of reports for the lesson
      acc[lessonId].count++;
      
      return acc;
    }, {});

  return (
    <section>
     
      <div className="App">
      <div className="container-fluid">
        <div className="row">
          {/* Left Navigation Sidebar */}
          <div className="col-md-3">
          <ParentLeftNav/>
          </div>

          {/* Main Content */}
          <div className="col-md-12 content">
            <Navbar/>
            <div className='innercontent p-20'>
             
            {students.student ? (
              <div className='row '>
                <div className='col-lg-9 stuprofile'>                     


                <div className="col">
      <div className="flip-card">
      <div>
        <div className="flip-card-front" style={{ borderRadius: '10px' }}>
          <div className="card-header" style={{backgroundImage: `url(${cardback})`, height:'150px', width:'100%',borderTopLeftRadius: '10px',borderTopRightRadius:'10px'}}>
            <img src={man1} className="avatar" alt="Avatar"/>
            </div>
          <div className="card-body" style={{textAlign:'center'}}>
            <h5 style={{fontWeight:'bold',fontsize:'16px',marginTop:'20px'}}>{students.student.stuName}</h5>
           
            <div>Grade:{students.student.stuGrade}</div>
            
              
          </div>
          <div className='row p-20' style={{textAlign:'center', fontWeight:'bold',fontSize:'14px'}}>
            <div className='col btnbdr bx-shadow m-r-10' style={{width:'200px'}}><img src={gl}/><br/>Lessons<br/><div style={{width:'160px',background:'#EDEDED',padding:'3px 10px',margin:'0 auto',borderRadius:'7px'}}>{students.student.stuLessonId.length}</div></div>
            <div className='col btnbdr bx-shadow m-r-10' style={{width:'200px'}}><img src={stickers}/><br/>Stickers<br/><div style={{width:'160px',background:'#EDEDED',padding:'3px 10px',margin:'0 auto',borderRadius:'7px'}}>{totalAchievementCount}</div></div>
            <div className='col btnbdr bx-shadow m-r-10' style={{width:'200px'}}><img src={coursecomp}/><br/>Courses Completed<br/><div style={{width:'160px',background:'#EDEDED',padding:'3px 10px',margin:'0 auto',borderRadius:'7px'}}>{lessonStatusCount}</div></div>
            <div className='col btnbdr bx-shadow' style={{width:'200px'}}><img src={coursecomp}/><br/>Courses Incompleted<br/><div style={{width:'160px',background:'#EDEDED',padding:'3px 10px',margin:'0 auto',borderRadius:'7px'}}>{lessonStatusCount}</div></div>
          </div>
           
        </div>
</div>
</div>
</div>



                <div className='row lessonspl' style={{marginTop:'20px'}}>
                <div><h5 style={{fontWeight:'bold'}}>Lessons</h5></div>
                <div className='col-lg-12'>
                
                <div className="card-container">
                {students.lessons ? (

students.lessons.map((lesson, index) => (
  <div className="card m-r-10">
    <img src={lesson.subcatimage} alt="Header" className="card-header" />
    <div className="card-body">
      <h5 className="card-title">{lesson.lessonname}</h5>
      <h6 style={{fontWeight:'600'}}>{lesson.subcategory}</h6>
      {/* Calculate the number of words left */}
      {transformedReport[lesson._id] && (
        <h6 className="card-text">
          <img style={{width:'15px'}} src={iconlesson}/> 
           &nbsp;{lesson.words.split(",").length}/{lesson.words.split(",").length - transformedReport[lesson._id].wordsspoken.length} words left
        </h6>
      )}
      <div class="progress">
        <div class="progress-sm progress-bar progress-bar-success"></div>
      </div>
    </div>
  </div>
))

                ): (
                  <p>Loading...</p>
                )}
                
                </div>
                </div>

                </div>
      <div className='row'>
      <div><h5 style={{fontWeight:'bold'}}>Stickers</h5></div> 

     
                            <div className='col-md-10 card m-r-10' style={{padding:'25px',textAlign:'center',fontWeight:'bold'}}><img style={{width:'70px',margin:'0 auto'}} src={sticker01}/><br/>Sticker Name</div>
                     
                            <div className='col-md-10 card m-r-10' style={{padding:'25px',textAlign:'center',fontWeight:'bold'}}><img style={{width:'70px',margin:'0 auto'}} src={sticker02}/><br/>Sticker Name</div>
                          
                          
                            <div className='col-md-10 card m-r-10' style={{padding:'25px',textAlign:'center',fontWeight:'bold'}}><img style={{width:'70px',margin:'0 auto'}} src={sticker03}/><br/>Sticker Name</div>
                         
                          
                            <div className='col-md-10 card m-r-10' style={{padding:'25px',textAlign:'center',fontWeight:'bold'}}><img style={{width:'70px',margin:'0 auto'}} src={sticker04}/><br/>Sticker Name</div>
                          
                            <div className='col-md-10 card m-r-10' style={{padding:'25px',textAlign:'center',fontWeight:'bold'}}><img style={{width:'70px',margin:'0 auto'}} src={sticker05}/><br/>Sticker Name</div>
                          
                            <div className='col-md-10 card' style={{padding:'25px',textAlign:'center',fontWeight:'bold'}}><img style={{width:'70px',margin:'0 auto'}} src={sticker06}/><br/>Sticker Name</div>
                          


      </div>

                  </div>  
                  <div className='col-lg-3'>
                  
                  {students.slps ? (
  students.slps.map((slp, index) => (
    <div className="col m-b-10" key={index}>
      <div className="flip-card stuprofile">
        <div className="flip-card-front" style={{ borderRadius: '10px' }}>
          <div className="card-header" style={{backgroundImage: `url(${tback})`, height:'128px', width:'100%',borderTopLeftRadius: '10px',borderTopRightRadius:'10px'}}>
          {slp && slp.avatar && <img src={`/`+slp.avatar.replace(/uploads\\/g, '')} alt="Avatar" className="avatar" style={{background:"#f3de94",border:'none'}}/>}
          </div>
          <h5 style={{fontWeight:'bold', fontSize:'16px', marginTop:'20px'}}>{slp.firstname+" "+slp.lastname}</h5>
          <div className="card-body" style={{textAlign:'center',color:'#838383'}}>
            
            <div>{slp.title}</div>
            <div>{slp.email}</div>
            <div>{slp.phone}</div>
            <div class="border m-t-30 rounded">
              <img className='p-t-10' src={star} style={{width:'20px'}}/>
              <div><h7 style={{fontWeight:'bold'}}>Rattings</h7></div>
              <div className='rounded m-15' style={{background:'#EDEDED',padding:'3px 40px'}}>{slp.ratting?slp.ratting:'No Rattings Found'}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ))
) : (
  <p>Loading...</p>
)}
                  
                          
                  </div>
              </div>


) : (
  <p>Loading...</p>
)}

            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
  );
};

StuProfile.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile
});

export default connect(mapStateToProps, { getCurrentProfile, deleteAccount })(
  StuProfile
);
