import React, { useEffect,useState  } from 'react';

import PropTypes from 'prop-types';
import { connect,useDispatch,useSelector   } from 'react-redux';

import { getCurrentProfile, deleteAccount } from '../../actions/profile';
import SlpNavbar from '../../components/layout/SlpNavbar';
import Navbar from '../../components/layout/Navbar';
import { getLessons,setFilter, setPage } from '../../actions/slp';
import lesson1 from '../../images/IMG.png';
import lesson2 from '../../images/IMG1.png';
import lesson3 from '../../images/IMG2.png';
import lesson4 from '../../images/IMG3.png';
import iconlesson from '../../images/Icon-Lessons.png';

import TotalStudent from '../dashboard/TotalStudentChart';
import ActiveInactiveStudent from '../dashboard/ActiveInactiveChart';
import Chart from '../dashboard/VerticalBarChart';
import HardWord from '../dashboard/HardWordChart';
const Analytics = ({
  isOpen,
  getCurrentProfile,
  deleteAccount,
  auth: { user },
  profile: { profile }
}) => {
  
  
  return (
    
    <section>
      <div className="App">
      <div className="container-fluid">
        <div className="row">
          {/* Left Navigation Sidebar */}
          <div className="col-md-3">
            <SlpNavbar/>
          </div>

          {/* Main Content */}
          <div className={`col-md-12 ${isOpen ? 'content' : 'togcontent'}`}>
            <Navbar/>
            <div className={`p-20 ${isOpen ? 'innercontent' : 'toginnercontent'}`}>
              

            <div className='row'>
             <div className='col-lg-12'>
<h6>Module Status</h6>
            
             <div className="card lessoncontainer">
      <div className="card-container">
    
       <Chart/>
       
      </div>
      
    </div>


             </div>
              </div>


              <div className='row'>
             <div className='col-lg-12'>
<h6>Total Number Of Student By Month</h6>
            
             <div className="card lessoncontainer">
      <div className="card-container">
       <TotalStudent/>
       
      </div>
      
    </div>


             </div>
              </div>


              <div className='row'>
             <div className='col-lg-12'>

             <h6>Active vs Inactive Students</h6>
             <div className="card lessoncontainer">
      <div className="card-container">
       <ActiveInactiveStudent/>
       
      </div>
      
    </div>


             </div>
              </div>
              <div className='row'>
             <div className='col-lg-12'>

             <h6>Hard Words vs Words</h6>
             <div className="card lessoncontainer">
      <div className="card-container">
       <HardWord/>
       
      </div>
      
    </div>


             </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
  );
};

Analytics.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  isOpen: state.auth.isOpen !== undefined ? state.auth.isOpen : true, // Assuming isOpen is stored in auth reducer
});

export default connect(mapStateToProps, { getCurrentProfile, deleteAccount })(
    Analytics
);
