// SlpNavbar component

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { logout, setIsOpen } from '../../actions/auth';
import logo from '../../images/tempo-talk-logo.svg';
import dashicon from '../../images/Icon-Dashboard.png';
import studenticon from '../../images/Icon-Students.png';
import analytics from '../../images/Icon-Analytics.png';
import openclose from '../../images/oc.png';
import lesson from '../../images/Icon-Lessons.png';
import signout from '../../images/sign-out.png';
import { getStudent } from '../../actions/students';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const SlpNavbar = ({ logout, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();

  const toggleMenu = () => {
    dispatch(setIsOpen(!isOpen));
  };
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsOpen(false);
    } 
  };
  const [showComponent, setShowComponent] = useState(false);
  const { id } = useParams();

  const student = useSelector((state) => state.student.students);

  useEffect(() => {
    if (id) {
      setShowComponent(true);
    } else {
      setShowComponent(false);
    }
    dispatch(getStudent(id));
    handleResize();
  }, [dispatch, id]);

  const currentPath = window.location.pathname;

  return (
    <div className={`left-nav ${isOpen ? 'open' : 'closed'}`}>
      <div className="sidebar-header">
        <div>
          <img src={logo} alt="logo" className='tempologo' />
        </div>
        {/* Toggle button */}
        <img
          src={openclose} // Use the imported openclose image
          alt="Toggle Menu"
          className={`${isOpen ? 'toggle-img' : 'togtoggle-img'}`}
          onClick={toggleMenu}
        />
      </div>
      <ul className={`sidebar-menu accordion-menu ${isOpen ? ' p-t-100' : ' '}`}>
        <li className={currentPath.startsWith('/slp/dashboard') ? 'active' : ''}>
        <a href='/slp/dashboard'><img className='menuicon' src={dashicon} alt="dashicon" />
        <span className="hide-sm">Dashboard</span></a>
        </li>
        <li className={currentPath.startsWith('/slp/lessons') ? 'active' : ''}>
        <a href="/slp/lessons">
          <img className='menuicon' src={lesson} alt="lesson" />
          <span className="hide-sm">Lessons</span>
          </a>
        </li>
        <li className={currentPath.startsWith('/slp/students') ? 'active' : ''}>
        <a href='/slp/students'><img className='menuicon' src={studenticon} alt="studenticon" /></a>
          <input type="checkbox" id="toggle1" />
          <label htmlFor="toggle1">
            <a href='/slp/students'><span className='hide-sm'>Students</span></a>
          </label>
          {showComponent && (
            <ul className="submenu acc" style={{ padding: 'padding: 15px' }}>
              <li style={{ padding: '6px 0 10px 28px !important' }}>
                <i className="fas fa-chevron-right" />{' '}
                {(student.student) ? student.student.stuName : ''}
              </li>
            </ul>
          )}
        </li>
        <li className={currentPath.startsWith('/slp/Analytics') ? 'active' : ''}>
        <a href='/slp/analytics'>
          <img className='menuicon' src={analytics} alt="settingicon" />
          <span className="hide-sm">Analytics</span></a>
        </li>
        <li className='btop'>
          <a onClick={logout} href="#!">
            <img src={signout} className='menuicon' style={{marginRight:'20px'}}/>{' '}
            <span className="hide-sm">Logout</span>
          </a>
        </li>
      </ul>
    </div>
  );
}

SlpNavbar.propTypes = {
  logout: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isOpen: state.auth.isOpen !== undefined ? state.auth.isOpen : true, // Set isOpen to true by default
});

export default connect(mapStateToProps, { logout, setIsOpen })(SlpNavbar);
