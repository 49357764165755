import React, { useEffect,useState  } from 'react';

import PropTypes from 'prop-types';
import { connect,useDispatch,useSelector   } from 'react-redux';

import { getCurrentProfile, deleteAccount } from '../../actions/profile';
import SlpNavbar from '../../components/layout/SlpNavbar';
import Navbar from '../../components/layout/Navbar';
import { getLessons,setFilter, setPage } from '../../actions/slp';
import lesson1 from '../../images/IMG.png';
import lesson2 from '../../images/IMG1.png';
import lesson3 from '../../images/IMG2.png';
import lesson4 from '../../images/IMG3.png';
import iconlesson from '../../images/Icon-Lessons.png';

const Lessons = ({
  isOpen,
  getCurrentProfile,
  deleteAccount,
  auth: { user },
  profile: { profile }
}) => {
  
  const dispatch = useDispatch();
 //const slplessons = useSelector((state) => state.slp.lessons);
 const { lessons, loading, error, filter, page,totalPages } = useSelector((state) => state.slp);
  
 const [showTooltip, setShowTooltip] = useState(false);
    useEffect(() => {
      dispatch(getLessons(filter, page));
    }, [dispatch,filter, page]);
  
  //const lessonsArray = Array.isArray(slplessons) ? slplessons : [slplessons];
  const handlePageChange = (newPage) => {
    dispatch(setPage(newPage));
  };
  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button key={i} className={page === i ? 'active' : ''} onClick={() => handlePageChange(i)}>
          {i}
        </button>
      );
    }
    return pageNumbers;
  };
  
  return (
    
    <section>
      <div className="App">
      <div className="container-fluid">
        <div className="row">
          {/* Left Navigation Sidebar */}
          <div className="col-md-3">
            <SlpNavbar/>
          </div>

          {/* Main Content */}
          <div className={`col-md-12 ${isOpen ? 'content' : 'togcontent'}`}>
            <Navbar/>
            <div className={`p-20 ${isOpen ? 'innercontent' : 'toginnercontent'}`}>
              <div className='row'>
              <div className='col-lg-10'><h4>Lessons</h4></div>
              

              <div className='col-lg-2 p-b-10'><a href='/slp/addlesson' className="btn btn-light" style={{width:'170px',margin:'3px'}}>Add New Lesson</a></div>
              </div>




              <div className='row'>
             <div className='col-lg-12'>

            
             <div className="lessoncontainer">
      <div className="card-container">
        <div className="lessonspl row">
          {lessons.map((lesson) => (
            <div className={`card m-r-10 col-md-3 ${isOpen ? '' : 'colapcard'}`}>
            <img src={lesson.subcatimage} alt="Header" className="card-header" />
            <div className="card-body">
              <h5 className="card-title">{lesson.lessonname}</h5>
              <h6 style={{fontWeight:'600',textTransform: 'capitalize'}}>{lesson.subcategory}</h6>
              <h6 className="card-text"><img style={{width:'15px'}} src={iconlesson}/>{lesson.studentReports.length > 0 ?lesson.words.split(",").length - lesson.studentReports[0].wordsspoken.length : '0'} / {lesson.words.split(",").length} words left</h6>
              
              {lesson.studentReports.length > 0 ? (
    <div>
      <div className="progress">
            <div className="progress-bar progress-bar-success" role="progressbar" style={{ width: `${(lesson.studentReports[0].wordsspoken.length / lesson.words.split(",").length) * 100}%` }} aria-valuenow={lesson.studentReports[0].wordsspoken.length} aria-valuemin="0" aria-valuemax={lesson.words.split(",").length}></div>
        </div>
        <div className="tuff-words-container" onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
            <div className="tuff-words-length">
                {lesson.studentReports[0].tuffwords.length} Hard words
                {showTooltip && (
                    <div className="custom-tooltip">
                        <span className="custom-tooltiptext">
                            {lesson.studentReports[0].tuffwords.join(", ")}
                        </span>
                    </div>
                )}
            </div>
        </div>
    </div>
) : (
    <div>no student assigned</div>
)}
            </div>
          </div>
          ))}
        </div>
       
      </div>
      
    </div>

    <div className="pagination">
        <button disabled={page === 1} onClick={() => handlePageChange(page - 1)}>Previous</button>
        {renderPageNumbers()}
        <button disabled={page === totalPages} onClick={() => handlePageChange(page + 1)}>Next</button>
      </div>

             </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
  );
};

Lessons.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  isOpen: state.auth.isOpen !== undefined ? state.auth.isOpen : true,
});

export default connect(mapStateToProps, { getCurrentProfile, deleteAccount })(
    Lessons
);
