import React, { useState } from 'react';
import { CognitoIdentityServiceProvider, config as AWSConfig } from 'aws-sdk';

// Set your AWS region here
AWSConfig.update({ region: 'us-east-2' });

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    email: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSignUp = async () => {
    try {
      const cognito = new CognitoIdentityServiceProvider();
      const params = {
        ClientId: '1pgk1c63evhi6tjnk34rdk2vt',
        Username: formData.username,
        Password: formData.password,
        UserAttributes: [
          {
            Name: 'email',
            Value: formData.email
          }
        ]
      };
      await cognito.signUp(params).promise();
      alert('Sign up successful!');
    } catch (error) {
      console.error('Error signing up:', error);
      alert('Error signing up: ' + error.message);
    }
  };

  return (
    <div>
      <input type="text" name="username" onChange={handleChange} />
      <input type="password" name="password" onChange={handleChange} />
      <input type="email" name="email" onChange={handleChange} />
      <button onClick={handleSignUp}>Sign Up</button>
    </div>
  );
};

export default RegistrationForm;
