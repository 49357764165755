export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const GET_REPOS = 'GET_REPOS';
export const NO_REPOS = 'NO_REPOS';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const GET_POSTS = 'GET_POSTS';
export const GET_POST = 'GET_POST';
export const POST_ERROR = 'POST_ERROR';
export const UPDATE_LIKES = 'UPDATE_LIKES';
export const DELETE_POST = 'DELETE_POST';
export const ADD_POST = 'ADD_POST';
export const ADD_COMMENT = 'ADD_COMMENT';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';
export const GET_STUDENTS = 'GET_STUDENTS';
export const GET_STUDENT = 'GET_STUDENT';
export const ADD_STUDENT = 'ADD_STUDENT';
export const ADD_STUDENT_FAILURE = 'ADD_STUDENT_FAILURE';
export const GET_STUDENTS_SUCCESS = 'GET_STUDENTS_SUCCESS';
export const GET_STUDENTS_FAILURE = 'GET_STUDENTS_FAILURE';
export const GET_STUDENTS_REQUEST = 'GET_STUDENTS_REQUEST';
export const SET_STUDENT_STATUS = 'SET_STUDENT_STATUS';
export const FETCH_STUDENT_COUNT_SUCCESS = 'FETCH_STUDENT_COUNT_SUCCESS';
export const FETCH_STUDENT_COUNT_FAILURE = 'FETCH_STUDENT_COUNT_FAILURE';
export const FETCH_ACTIVE_STUDENT_COUNT_SUCCESS = 'FETCH_ACTIVE_STUDENT_COUNT_SUCCESS';
export const DELETE_STUDENT_SUCCESS = 'DELETE_STUDENT_SUCCESS';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const ADD_PARENT_SUCCESS = 'ADD_PARENT_SUCCESS';
export const ADD_PARENT_FAILURE = 'ADD_PARENT_FAILURE';
export const ADD_STUDENT_SUCCESS = 'ADD_STUDENT_SUCCESS';
export const GET_PARENT_SUCCESS = 'GET_PARENT_SUCCESS';
export const GET_PARENT_FAILURE = 'GET_PARENT_FAILURE';
export const FETCH_PARENT_REQUEST = 'FETCH_PARENT_REQUEST';
export const SET_FILTER = 'SET_FILTER';
export const SET_PAGE = 'SET_PAGE';
export const GET_LESSON_REQUEST = 'GET_LESSON_REQUEST';
export const GET_LESSONS_SUCCESS = 'GET_LESSONS_SUCCESS';
export const GET_LESSONS_FAILURE = 'GET_LESSONS_FAILURE';
export const FETCH_STUDENTS_SUCCESS = 'FETCH_STUDENTS_SUCCESS';
export const IMPORT_LESSONS_REQUEST = 'IMPORT_LESSONS_REQUEST';
export const IMPORT_LESSONS_SUCCESS = 'IMPORT_LESSONS_SUCCESS';
export const IMPORT_LESSONS_FAILURE = 'IMPORT_LESSONS_FAILURE';
export const ADD_NEW_SLP = 'ADD_NEW_SLP';
export const ADD_NEW_SLP_FAILURE = 'ADD_NEW_SLP_FAILURE';
export const GET_TEACHERS_REQUEST = 'GET_TEACHERS_REQUEST';
export const GET_TEACHERS_SUCCESS = 'GET_TEACHERS_SUCCESS';
export const GET_TEACHERS_FAILURE = 'GET_TEACHERS_FAILURE';
export const GET_TEACHER = 'GET_TEACHER';
export const GET_TEACHERS_GRID = 'GET_TEACHERS_GRID';
export const GET_PARENT_PROFILE = 'GET_PARENT_PROFILE';
export const FETCH_STUDENT_REQUEST = 'FETCH_STUDENT_REQUEST';
export const FETCH_STUDENT_SUCCESS = 'FETCH_STUDENT_SUCCESS';
export const FETCH_STUDENT_FAILURE = 'FETCH_STUDENT_FAILURE';
export const UPDATE_STUDENT_REQUEST = 'UPDATE_STUDENT_REQUEST';
export const UPDATE_STUDENT_SUCCESS = 'UPDATE_STUDENT_SUCCESS';
export const UPDATE_STUDENT_FAILURE = 'UPDATE_STUDENT_FAILURE';
export const GET_SLP_SUCCESS = 'GET_SLP_SUCCESS';
export const GET_SLP_FAILURE = 'GET_SLP_FAILURE';
export const GET_SLP_COUNT = 'GET_SLP_COUNT';
export const GET_SLP_COUNT_FAILURE = 'GET_SLP_COUNT_FAILURE';
export const GET_STUDENT_COUNT = 'GET_STUDENT_COUNT';
export const GET_STUDENT_COUNT_FAILURE = 'GET_STUDENT_COUNT_FAILURE';
export const GET_ACTIVE_SLP_COUNT = 'GET_ACTIVE_SLP_COUNT';
export const GET_ACTIVE_SLP_COUNT_FAILURE = 'GET_ACTIVE_SLP_COUNT_FAILURE';
export const GET_ACTIVE_STUDENT_COUNT = 'GET_ACTIVE_STUDENT_COUNT';
export const GET_ACTIVE_STUDENT_COUNT_FAILURE = 'GET_ACTIVE_STUDENT_COUNT_FAILURE';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export const GET_REPORT_FAILURE = 'GET_REPORT_FAILURE';
export const GET_LESSONS_COUNT_SUCCESS = 'GET_LESSONS_COUNT_SUCCESS';
export const GET_LESSONS_COUNT_FAILURE = 'GET_LESSONS_COUNT_FAILURE';
export const GET_ACHIEVEMENT_COUNT_SUCCESS = 'GET_ACHIEVEMENT_COUNT_SUCCESS';
export const GET_ACHIEVEMENT_COUNT_FAILURE = 'GET_ACHIEVEMENT_COUNT_FAILURE';
export const SET_IS_OPEN = 'SET_IS_OPEN ';
