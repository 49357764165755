import api from '../utils/api';
import {
    GET_PARENT_PROFILE,
    GET_PARENT_FAILURE,
    FETCH_STUDENT_REQUEST,
    FETCH_STUDENT_SUCCESS,
    FETCH_STUDENT_FAILURE,
    UPDATE_STUDENT_REQUEST,
    UPDATE_STUDENT_SUCCESS,
    UPDATE_STUDENT_FAILURE
} from './types';

  

  export const getParent = (email) => {
    return (dispatch) => {
        api.get(`/students/getstudentparent/${email}`)
        .then(res => {
            const { students, parent, lessons, slps } = res.data;
          dispatch({ type: GET_PARENT_PROFILE, payload: { students, parent, lessons, slps } });
        })
        .catch(err => {
          dispatch({ type: GET_PARENT_FAILURE, payload: err.message });
        });
    };
  };


  export const fetchStudentById = (id) => { 
    return (dispatch) => {
        api.get(`/students/geteditstu/${id}`)
        .then(res => {
         
          dispatch({ type: FETCH_STUDENT_SUCCESS, payload:res.data });
        })
        .catch(err => {
          dispatch({ type: FETCH_STUDENT_FAILURE, payload: err.message });
        });
    };
  };


  export const updateStudent = (stuid, studentData,newStudentId) => {
    return (dispatch) => {
        const formData = new FormData();
        formData.append('avatar', studentData.profileImage); // Assuming profileImage is the file field
        formData.append('studentName', studentData.studentName); // Assuming other fields are included in studentData
        formData.append('studentGrade', studentData.studentGrade);
        formData.append('studentDob', studentData.studentDob);
        formData.append('_id', studentData._id);
        formData.append('newStudentId',newStudentId);
       
        api.put(`/students/update/${stuid}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(res => {
            dispatch({ type: UPDATE_STUDENT_SUCCESS, payload: res.data });
        })
        .catch(err => {
            dispatch({ type: UPDATE_STUDENT_FAILURE, payload: err.message });
        });
    };
};

export const updateParent = (parentData) => {
  return (dispatch) => {console.log(parentData);
      const formData = new FormData();
      formData.append('avatar', parentData.profileImage); // Assuming profileImage is the file field
      formData.append('parentName', parentData.parentName); // Assuming other fields are included in studentData
      formData.append('parentEmail', parentData.parentEmail);
      formData.append('parentPhone', parentData.parentPhone);
      formData.append('pid', parentData.pid);
      api.put(`/students/updateparent`, formData, {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
      })
      .then(res => {
          dispatch({ type: UPDATE_STUDENT_SUCCESS, payload: res.data });
      })
      .catch(err => {
          dispatch({ type: UPDATE_STUDENT_FAILURE, payload: err.message });
      });
  };
};