import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Landing from './components/layout/Landing';
import Register from './components/auth/Register';
import Login from './components/auth/Login';
import ResetPassword from './components/auth/ChangePassword';
import Alert from './components/layout/Alert';
import Dashboard from './components/dashboard/Dashboard';
import SlpDashboard from './components/dashboard/SlpDashboard';
import parentdash from './components/parent/parentdash';
import ProfileForm from './components/profile-forms/ProfileForm';
import AddExperience from './components/profile-forms/AddExperience';
import AddEducation from './components/profile-forms/AddEducation';
import Profiles from './components/profiles/Profiles';
import Profile from './components/profile/Profile';
import Posts from './components/posts/Posts';
import Post from './components/post/Post';
import NotFound from './components/layout/NotFound';
import PrivateRoute from './components/routing/PrivateRoute';
import { LOGOUT } from './actions/types';
import SlpLogin from './components/slp/Login';
import ParentLogin from './components/parent/Login';
import SlpLessons from './components/slp/Lessons';
import SlpStudents from './components/slp/Students';
import StudentsProfile from './components/slp/StudentProfile';
import AddLesson from './components/slp/AddLesson';
import SlpProfile from './components/slp/Profile';
import AdminTeachers from './components/admin/teachers';
import AddTeacher from './components/admin/AddTeacher';
import TeachersProfile from './components/admin/addeditform';
import adminstudents from './components/admin/students';
import AdminStudentsProfile from './components/admin/StudentProfile';
import StuProfile from './components/parent/StuProfile';
import EditProfile from './components/parent/EditProfile';
import SlpEditProfile from './components/slp/EditProfile';
import ForgotPassword from './components/layout/ForgotPassword';
import SlpAnalytics from './components/slp/Analytics';
// Redux
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';

import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

const App = () => {
  useEffect(() => {
    // check for token in LS when app first runs
    if (localStorage.token) {
      // if there is a token set axios headers for all requests
      setAuthToken(localStorage.token);
    }
    // try to fetch a user, if no token or invalid token we
    // will get a 401 response from our API
    store.dispatch(loadUser());

    // log user out from all tabs if they log out in one tab
    window.addEventListener('storage', () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);

  return (
    <Provider store={store}>
      <Router>
        
        <Alert />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="resetpassword" element={<ResetPassword/>}/>
          <Route path="login" element={<Login />} />
          <Route path="profiles" element={<Profiles />} />
          <Route path="/slp/login" element={<SlpLogin/>} />
          <Route path="/parent/login" element={<ParentLogin/>} />
          <Route path="forgotpassword" element={<ForgotPassword />} />
          
          <Route path="profile/:id" element={<Profile />} />
          <Route path="slp/lessons" element={<PrivateRoute component={SlpLessons} />}/>
          <Route path="slp/profile" element={<PrivateRoute component={SlpProfile} />}/>
          <Route path="slp/addlesson" element={<PrivateRoute component={AddLesson} />}/>
          <Route path="slp/students" element={<PrivateRoute component={SlpStudents} />}/>
          <Route path="slp/studentprofile/:id" element={<PrivateRoute component={StudentsProfile} />} />
          <Route path="admin/teachers" element={<PrivateRoute component={AdminTeachers} />}/>
          <Route path="admin/addteacher" element={<PrivateRoute component={AddTeacher} />}/>
          <Route path="admin/teacherprofile/:id" element={<PrivateRoute component={TeachersProfile} />} />
          <Route path="admin/students" element={<PrivateRoute component={adminstudents} />}/>
          <Route path="admin/studentprofile/:id" element={<PrivateRoute component={AdminStudentsProfile} />} />
          <Route path="parent/profile/:id" element={<PrivateRoute component={StuProfile} />} />
          <Route path="parent/editprofile/:stuid" element={<PrivateRoute component={EditProfile} />} />
          <Route path="slp/editprofile/:stuid" element={<PrivateRoute component={SlpEditProfile} />} />
          <Route path="slp/analytics/" element={<PrivateRoute component={SlpAnalytics} />} />
          <Route
            path="dashboard"
            element={<PrivateRoute component={Dashboard} />}
          />
          <Route
            path="/slp/dashboard"
            element={<PrivateRoute component={SlpDashboard} />}
          />
          <Route
            path="/parent/dashboard"
            element={<PrivateRoute component={parentdash} />}
          />
          <Route
            path="/parent/editprofile"
            element={<PrivateRoute component={EditProfile} />}
          />
          <Route
            path="create-profile"
            element={<PrivateRoute component={ProfileForm} />}
          />
          <Route
            path="edit-profile"
            element={<PrivateRoute component={ProfileForm} />}
          />
          <Route
            path="add-experience"
            element={<PrivateRoute component={AddExperience} />}
          />
          <Route
            path="add-education"
            element={<PrivateRoute component={AddEducation} />}
          />
          <Route path="posts" element={<PrivateRoute component={Posts} />} />
          <Route path="posts/:id" element={<PrivateRoute component={Post} />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Router>
    </Provider>
  );
};

export default App;
