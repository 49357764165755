import React, { useEffect,useState  } from 'react';

import PropTypes from 'prop-types';
import { connect,useDispatch, useSelector } from 'react-redux';
import { getStudents, setFilter, setPage } from '../../actions/students';

import { getCurrentProfile, deleteAccount } from '../../actions/profile';
import SlpNavbar from '../../components/layout/SlpNavbar';
import Navbar from '../../components/layout/Navbar';

import cardback from '../../images/cardback.png';

import man1 from '../../images/man1.png';
import man2 from '../../images/man2.png';
import man3 from '../../images/fem3.png';
import man4 from '../../images/user-male.png';
import man5 from '../../images/user-female.png';
import rvl from '../../images/glesson.png';

import stickers from '../../images/ws.png';


import sticker01 from '../../images/sticker-01.png';
import sticker02 from '../../images/sticker-02.png';
import sticker03 from '../../images/sticker-03.png';
import sticker04 from '../../images/sticker-04.png';
import sticker05 from '../../images/sticker-05.png';
import sticker06 from '../../images/sticker-06.png';

const Students = ({
  isOpen,
  getCurrentProfile,
  auth: { user },
  profile: { profile }
}) => {
  

  const [flippedLessons, setFlippedLessons] = useState([]);
  const [flippedStickers, setFlippedStickers] = useState([]);
  const [startLessonIndex, setStartLessonIndex] = useState(0);
  const numToShow = 6;
  const flipCard = (type, id) => {
    if (type === 'lessons') {
      if (flippedLessons.includes(id)) {
        setFlippedLessons(flippedLessons.filter(cardId => cardId !== id));
      } else {
        setFlippedLessons([...flippedLessons, id]);
      }
    } else if (type === 'stickers') {
      if (flippedStickers.includes(id)) {
        setFlippedStickers(flippedStickers.filter(cardId => cardId !== id));
      } else {
        setFlippedStickers([...flippedStickers, id]);
      }
    }
  };


  const dispatch = useDispatch();
   //const students = useSelector((state) => state.student.students);
   const { students, loading, error, filter, page,totalPages } = useSelector((state) => state.student);

    useEffect(() => {
      dispatch(getStudents(filter, page));
    }, [dispatch, filter, page]);



  // Generate card data
  const cardsData = Array.from({ length: 15 }, (_, index) => ({
    id: `card${index + 1}`,
    frontContent: `Front Content ${index + 1}`,
    backContent: `Back Content ${index + 1}`,
  }));

  // Chunk cards into arrays of 5
  const chunkedCards = [];
  for (let i = 0; i < cardsData.length; i += 5) {
    chunkedCards.push(cardsData.slice(i, i + 5));
  }
  
  const avatarImages = [man1, man2, man3,man4,man5];
  
  const handleFilterChange = (event) => {
    dispatch(setFilter(event.target.value));
  };

  const handlePageChange = (newPage) => {
    dispatch(setPage(newPage));
  };
  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button key={i} className={page === i ? 'active' : ''} onClick={() => handlePageChange(i)}>
          {i}
        </button>
      );
    }
    return pageNumbers;
  };
  
  const toggleShowMore = () => {
    setStartLessonIndex(startLessonIndex + numToShow);
  };
  return (
    <section>
      <div className="App">
      <div className="container-fluid">
        <div className="row">
          {/* Left Navigation Sidebar */}
          <div className="col-md-3">
            <SlpNavbar/>
          </div>

          {/* Main Content */}
          <div className={`col-md-12 ${isOpen ? 'content' : 'togcontent'}`}>
            <Navbar/>
            <div className={`p-20 ${isOpen ? 'innercontent' : 'toginnercontent'}`}>
             <div className='row'>
             <div className='col float-left'><h4 style={{fontWeight:'bold'}}>Students</h4></div>
             <div className='col-md-3 float-right' style={{textAlign:'right',paddingTop:'8px'}}><i className="fas fa-filter"/></div>
              <div className='col-md-3 float-right' style={{width:'210px'}}>
              <select class="form-select float-right" aria-label="Default select example" style={{width:'200px',marginBottom:'10px'}} value={filter} onChange={handleFilterChange}>
              <option value="all">All</option>
          <option value="active">Sort by: Active</option>
          <option value="inactive">Sort by: Inactive</option>
  
</select>
              </div>
             </div>
           
            <div className="row">
      
            
            {students.map((student,index) => ( 
        <div key={student._id} className="col-md-3" style={{ marginBottom: '20px'}}>
          <div className="flip-card">
            <div className={`flip-card-inner ${(flippedLessons.includes(student._id) || flippedStickers.includes(student._id)) ? 'flipped' : ''}`}>
              <div className= {student.stuStatus == 'Inactive' ? 'flip-card-front bdrRed' : 'flip-card-front'} style={{ borderRadius: '10px' }}>
                <div className="card-header" style={{backgroundImage: `url(${cardback})`, height:'130px', width:'100%',borderTopLeftRadius: '10px',borderTopRightRadius:'10px'}}>
                {student && student.avatar ? (
  <img 
    src={`/${student.avatar.replace(/uploads\\/g, '')}`} 
    alt="Avatar" className="avatar" alt="Avatar"  />
) : (
  <img src={avatarImages[index % avatarImages.length]} alt={`Avatar ${index % avatarImages.length + 1}`} className="avatar" alt="Avatar"/>
)}
                </div>
                <div className="card-body" style={{paddingTop:'80px',textAlign:'center',margin: '0 auto'}}>
                  <h5 style={{fontWeight:'bold',fontSize:'16px'}}>Student ID: {student._id}</h5>
                  <div className='center' style={{paddingLeft:'25px'}}>
                    <div className='btnbdr float-left'>
                      <img src={rvl} alt="rvl"/>
                      <div style={{fontWeight:'bold',fontSize:'16px'}}>Lessons</div>
                      <div className={student.stuStatus == 'Inactive' ? 'courBdrred' : 'courBdrgreen'} onClick={() => flipCard('lessons', student._id)}>{student.stuLessonId.length}</div>
                    </div>
                    <div className='btnbdr float-left'>
                      <img src={stickers} alt="stickers"/>
                      <div style={{fontWeight:'bold',fontSize:'16px'}}>Stickers</div>
                      <div className='courBdrgreen' onClick={() => flipCard('stickers', student._id)}>

                      {student.studentReports ? student.studentReports.reduce((total, report) => total + (report.lachievement ? report.lachievement.length : 0), 0) : 0}

                      </div>
                    </div>
                    <a href={`/admin/studentprofile/${student._id}`} class="btn btn-secondary btn-sm" style={{background:'#008000',padding:'5px 30px 5px 30px',marginTop:'20px',width:'190px',marginRight:'45px'}} role="button" aria-pressed="true">View Profile</a>
                  </div>
                </div>
              </div>
              <div className="flip-card-back" style={{ display: (flippedLessons.includes(student._id) || flippedStickers.includes(student._id)) ? 'block' : 'none' }}>
                {/* Back content */}
                <div className="card text-white" style={{backgroundImage: `url(${cardback})`,height:'100%'}}>
                  <div className= {index === 0 ? 'card-body bdrRed' : 'card-body'}>
                    <h6><span>Student Id: </span>{student._id}</h6>
                    <h6><span>Grade: </span>{student.stuGrade}</h6>
                    <h6><span>Registered: </span>{student.date}</h6>
                    <>
                          {flippedLessons.includes(student._id) && (
                            <>
                              {student.stuLessonId.slice(startLessonIndex, startLessonIndex + numToShow).map((lessonId, idx) => (
                                <div key={idx}>
                                  {student && student.lessonMap && student.lessonMap[lessonId] ? student.lessonMap[lessonId] : ''}
                                  <div className="progress">
                                    <div className="progress-sm progress-bar-success" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                                  </div>
                                </div>
                              ))}
                              <a href="#" className="btn btn-secondary" onClick={() => { setStartLessonIndex(0); setFlippedLessons(flippedLessons.filter(cardId => cardId !== student._id)); }}>Close</a>
                              {student.stuLessonId.length > startLessonIndex + numToShow && (
                                <a href="#" style={{marginTop:'5px'}} className="btn btn-primary btn-sm" onClick={toggleShowMore}>Show more</a>
                              )}
                            </>
                          )}
                        </>
                        {flippedStickers.includes(student._id) && (
  <div className='row'>
    {student.studentReports ? (
      student.studentReports.map((report, reportIdx) => (
        report.lachievement.map((achievement, achievementIdx) => (
          <div key={achievementIdx} className='col'>
            <img style={{width:'60px',padding:'7px'}} src={sticker01} alt={`sticker${achievementIdx}`} />
          </div>
        ))
      ))
    ) : ('')}
   <div> 
    <a href="#" className="btn btn-secondary" onClick={() => setFlippedStickers(flippedStickers.filter(cardId => cardId !== student._id))}>Close</a>
    <a href={`/admin/studentprofile/${student._id}`} className="btn btn-secondary btn-sm" style={{background:'#008000',padding:'5px 30px 5px 30px',marginTop:'20px',width:'190px'}} role="button" aria-pressed="true">View Profile</a>
  </div>
  </div>
)}
                    
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div>
      <div className="pagination">
        <button disabled={page === 1} onClick={() => handlePageChange(page - 1)}>Previous</button>
        {renderPageNumbers()}
        <button disabled={page === totalPages} onClick={() => handlePageChange(page + 1)}>Next</button>
      </div>
    </div>
    
    




    </div>
    
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
  );
};

Students.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  isOpen: state.auth.isOpen !== undefined ? state.auth.isOpen : true,
});

export default connect(mapStateToProps, { getCurrentProfile, deleteAccount })(
    Students
);
